import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import repairService from "../../assets/images/sellproduct.jpg";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import DirectionsCarOutlinedIcon from "@mui/icons-material/DirectionsCarOutlined";
import ComputerOutlinedIcon from "@mui/icons-material/ComputerOutlined";
import CameraRearOutlinedIcon from "@mui/icons-material/CameraRearOutlined";
import TwoWheelerOutlinedIcon from "@mui/icons-material/TwoWheelerOutlined";
import BathroomOutlinedIcon from "@mui/icons-material/BathroomOutlined";
import LocalLaundryServiceOutlinedIcon from "@mui/icons-material/LocalLaundryServiceOutlined";
import KitchenOutlinedIcon from "@mui/icons-material/KitchenOutlined";
import DesktopMacOutlinedIcon from "@mui/icons-material/DesktopMacOutlined";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import Fuse from "fuse.js";
const repairServices = [
  {
    name: "AC",
    icon: <AcUnitIcon />,
  },
  {
    name: "Car",
    icon: <DirectionsCarOutlinedIcon />,
  },
  {
    name: "Computer",
    icon: <DesktopMacOutlinedIcon />,
  },
  {
    name: "Laptop",
    icon: <ComputerOutlinedIcon />,
  },
  {
    name: "Mobile Phone",
    icon: <CameraRearOutlinedIcon />,
  },
  {
    name: "Motorcycle",
    icon: <TwoWheelerOutlinedIcon />,
  },
  {
    name: "Refrigerator",
    icon: <KitchenOutlinedIcon />,
  },
  {
    name: "TV",
    icon: <DesktopWindowsOutlinedIcon />,
  },
  {
    name: "Washing Machine",
    icon: <LocalLaundryServiceOutlinedIcon />,
  },
  {
    name: "Water Purifier",
    icon: <BathroomOutlinedIcon />,
  },
];
const RepairServices = () => {
  const [query, setQuery] = useState("");
  const handleSearchClick = (query) => {
    const option = {
      isCaseSensitive: true,
      includeScore: false,
      shouldSort: true,
      includeMatches: false,
      findAllMatches: false,
      minMatchCharLength: 1,
      location: 0,
      threshold: 0.6,
      distance: 100,
      useExtendedSearch: true,
      ignoreLocation: false,
      ignoreFieldNorm: false,
      fieldNormWeight: 1,
      keys: ["name"],
    };

    const fuse = new Fuse(repairServices, option);
    const result = fuse.search(query);

    const searchedResult = result.map((result) => result.item);

    setQdata(searchedResult);
  };
  useEffect(() => {
    if (query !== "") {
      setTimeout(() => handleSearchClick(query), 100);
    }
  }, [query]);
  const [qdata, setQdata] = useState([]);
  return (
    <div>
      {/* <Box>
        <img src={repairService} alt="doctor" width={"100%"} height={300} />
      </Box> */}
      <Box
        sx={{
          backgroundImage: `url(${repairService})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "contain",
          height: 300, // Set the height of the box
          width: "100%",
        }}
      />
      <Container>
        <Box sx={{ mt: "2rem" }}>
          <TextField
            variant="outlined"
            label=""
            size="small"
            name="company"
            placeholder="Search all category"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <SearchIcon sx={{ color: "#000", marginRight: "8px" }} />
              ),
              sx: {
                // width: "350px",
                marginBottom: "2rem",
              },
            }}
          />
        </Box>
        <Grid
          container
          // display={"flex"}
          // justifyContent={"center"}
          sx={{ mb: "2rem" }}
          columnSpacing={5}
          rowSpacing={2}
        >
          {query === ""
            ? repairServices.map((item, i) => (
                <Grid item key={i} sm={6} xs={12} md={4} xl={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      background: i % 2 === 0 ? "#fbfbfb" : "",
                      padding: "1rem",
                      cursor: "pointer",
                      overflow: "hidden",
                      position: "relative",
                      "&:hover": {
                        "& > div > :last-child": {
                          color: "blue",
                          transition: "transform 0.3s ease-in-out",
                          transform: "translateX(5%)",
                        },
                      },
                    }}
                    component={"div"}
                  >
                    <Box display={"flex"}>
                      {item.icon}
                      <Typography ml={2}>{item.name}</Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))
            : qdata.map((item, i) => (
                <Grid item key={i} sm={6} xs={12} md={4} xl={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      background: i % 2 === 0 ? "#fbfbfb" : "",
                      padding: "1rem",
                      cursor: "pointer",
                      overflow: "hidden",
                      position: "relative",
                      "&:hover": {
                        "& > div > :last-child": {
                          color: "blue",
                          transition: "transform 0.3s ease-in-out",
                          transform: "translateX(5%)",
                        },
                      },
                    }}
                    component={"div"}
                  >
                    <Box display={"flex"}>
                      {item.icon}
                      <Typography ml={2}>{item.name}</Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))}
        </Grid>
      </Container>
    </div>
  );
};

export default RepairServices;
