import React, { useLayoutEffect } from "react";
import Navbar from "../Navbar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Footer";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default function Layout() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "flex-start",
      }}
    >
      <Navbar />

      <main
        style={{
          marginTop: "4rem",
        }}
      >
        <Wrapper>
          <Outlet />
        </Wrapper>
      </main>

      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </div>
  );
}
