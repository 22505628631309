import {
  Autocomplete,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import img1 from "../../assets/img/courierbook.jpg";
import img2 from "../../assets/img/bookacab.png";
import img3 from "../../assets/img/doct.jpg";
import img4 from "../../assets/img/insurancenew.jpg";
import img5 from "../../assets/img/sellyourelectrnocis.png";
import img7 from "../../assets/img/mobilerepairengineer.jpg";
import img6 from "../../assets/img/pathology.jpg";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { useNavigate } from "react-router-dom";
import PlaceIcon from "@mui/icons-material/Place";
import { City } from "country-state-city";
import { Search } from "@mui/icons-material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Fuse from "fuse.js";
const cardImageData = [
  {
    title: "Book a courier",
    subtitle: "book it now",
    path: "/book-courier",
    backgroundImg: img1,
    backgroundColor: "white",
  },
  {
    title: "Book pathology test",
    subtitle: "book it now",
    path: "/pathology",
    backgroundImg: img6,
    backgroundColor: "white",
  },
  {
    title: "Book doctor",
    subtitle: "book it now",
    path: "/book-doctor",
    backgroundImg: img3,
    backgroundColor: "#dfe5e3",
  },
  {
    title: "Book a cab",
    subtitle: "book it now",
    path: "/book-cab",
    backgroundImg: img2,
    backgroundColor: "#f8f8f8",
  },
  {
    title: "Buy insurance",
    subtitle: "book it now",
    path: "/insurance",
    backgroundImg: img4,
    backgroundColor: "#71c3ce",
  },
  {
    title: "Sell your electronics",
    subtitle: "book it now",
    path: "/sell-electronic",
    backgroundImg: img5,
    backgroundColor: "white",
  },
  {
    title: "Repair & Services",
    subtitle: "book it now",
    path: "/repair-services",
    backgroundImg: img7,
    backgroundColor: "white",
  },
];

const Home = () => {
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);
  const [address, setAddress] = useState("");
  const city = City.getCitiesOfCountry("IN");
  const handleAddressFieldFocus = (event, focused) => {
    setShowButton(focused);
  };
  const handleLocationDetection = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log(latitude, longitude);
          // Use latitude and longitude to fetch an address or update the address directly
          // For example, updating the 'address' state:
          setAddress(`Latitude: ${latitude}, Longitude: ${longitude}`);
          handleAddressFieldFocus(false);
        },
        (error) => {
          handleAddressFieldFocus(false);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error("User denied the request for Geolocation.");
              // Handle denied permission error here
              break;
            case error.POSITION_UNAVAILABLE:
              console.error("Location information is unavailable.");
              // Handle unavailable location error here
              break;
            case error.TIMEOUT:
              console.error("The request to get user location timed out.");
              // Handle timeout error here
              break;
            default:
              console.error("An unknown error occurred.");
              // Handle other errors here
              break;
          }
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      handleAddressFieldFocus(false);
      // Handle lack of Geolocation support here
    }
  };

  const handleAddressClick = (name) => {
    setAddress(name);
    handleAddressFieldFocus(false);
  };

  const [query, setQuery] = useState("");
  const handleSearchClick = (query) => {
    const option = {
      isCaseSensitive: true,
      includeScore: false,
      shouldSort: true,
      includeMatches: false,
      findAllMatches: false,
      minMatchCharLength: 1,
      location: 0,
      threshold: 0.6,
      distance: 100,
      useExtendedSearch: true,
      ignoreLocation: false,
      ignoreFieldNorm: false,
      fieldNormWeight: 1,
      keys: ["title"],
    };

    const fuse = new Fuse(cardImageData, option);
    const result = fuse.search(query);

    const searchedResult = result.map((result) => result.item);

    setQdata(searchedResult);
  };
  useEffect(() => {
    if (query !== "") {
      setTimeout(() => handleSearchClick(query), 100);
    }
  }, [query]);
  const [qdata, setQdata] = useState([]);
  return (
    <div>
      <Container sx={{ my: "2rem" }}>
        <Grid
          //
          container
          spacing={2}
        >
          {/* <Grid item xs={12} sm={5} md={5} lg={5}>
            <Autocomplete
              options={city.map((state) => state.name)} // Assuming state is an array of state objects
              onChange={(e, value) => setAddress(value)} // On selecting a state, set it to selectedState
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  type="text"
                  required
                  placeholder="address"
                  label=""
                  value={address}
                  sx={{ mt: 1, mb: 2 }}
                  size="small"
                  onChange={(e) => setAddress(e.target.value)}
                  id="name"
                />
              )}
            />
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={5}
            md={5}
            lg={5}
            style={{ position: "relative" }}
            onFocus={(event) => handleAddressFieldFocus(event, true)}
            // onBlur={(event) => handleAddressFieldFocus(event, false)}
          >
            <TextField
              // {...params}

              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <PlaceIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
              variant="outlined"
              type="text"
              required
              placeholder="Address"
              label=""
              value={address}
              sx={{ mt: 1, mb: 2 }}
              size="small"
              onChange={(e) => setAddress(e.target.value)}
              id="name"
            />

            {showButton && (
              <Box
                fullWidth
                sx={{
                  position: "absolute",
                  color: "blue",
                  display: "flex",
                  background: "white",
                  flexDirection: "column",
                  cursor: "pointer",
                  gap: 1,
                  padding: "0.5rem 1rem",
                  zIndex: "1",
                  border: "1px solid blue",
                  width: "300px",
                  textAlign: "left",
                }}
                variant="contained"
              >
                <Box display={"flex"} onClick={handleLocationDetection}>
                  <GpsFixedIcon fontSize="small" /> Detect Location
                </Box>
                <Box maxHeight={"300px"} overflow={"scroll"}>
                  {address !== "" &&
                    city.map((state) => (
                      <Typography
                        sx={{ color: "black" }}
                        onClick={() => handleAddressClick(state.name)}
                      >
                        {state.name}
                      </Typography>
                    ))}
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={5}>
            <TextField
              variant="outlined"
              fullWidth
              type="text"
              required
              placeholder="Type your keywords..."
              label=""
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              // value={address}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleSearchClick(query)}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ mt: 1, mb: 2 }}
              size="small"
              // onChange={(e) => setAddress(e.target.value)}
              id="name"
            />
          </Grid>
        </Grid>

        <Box my={5}>
          <Grid
            display={"flex"}
            justifyContent={"center"}
            container
            spacing={5}
          >
            {query === ""
              ? cardImageData.map((item, i) => (
                  <Grid item xs={12} key={i} sm={6} md={3} xl={3}>
                    <Paper
                      onClick={() => navigate(item.path)}
                      sx={{
                        color: "black",
                        borderRadius: "20px",
                        background: item.backgroundColor,
                        height: "400px",
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                        transition: "transform 0.3s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.02)",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          flexGrow: 1,
                          marginRight: "20px",
                          backgroundImage: `url(${item.backgroundImg})`,
                          transition: "transform 0.3s ease-in-out",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <Box sx={{ paddingTop: "1rem", paddingLeft: "1rem" }}>
                          <Typography
                            fontWeight={"bold"}
                            sx={{
                              transition: "transform 0.3s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.1)",
                              },
                              color: "black",
                            }}
                          >
                            {item.title.toUpperCase()}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ transition: "transform 0.3s ease-in-out" }}
                          >
                            {item.subtitle}
                          </Typography>
                          <Box sx={{ marginTop: "250px" }}>
                            <Button>
                              Explore
                              <NavigateNextIcon />
                            </Button>
                          </Box>
                        </Box>
                      </Box>

                      {/* <img
                    src={item.backgroundImg}
                    width={"100%"}
                    height={400}
                    style={{
                      marginBottom: "-0.3rem",
                      transition: "transform 0.3s ease-in-out",
                      objectFit: "cover",
                      transformOrigin: "center right",
                      position: "absolute",
                      right: "0",
                      bottom: "0",
                    }}
                    alt={item.title}
                  /> */}
                    </Paper>
                  </Grid>
                ))
              : qdata.map((item, i) => (
                  <Grid item xs={12} key={i} sm={6} md={3} xl={3}>
                    <Paper
                      onClick={() => navigate(item.path)}
                      sx={{
                        color: "black",
                        borderRadius: "20px",
                        background: item.backgroundColor,
                        height: "400px",
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                        transition: "transform 0.3s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.02)",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          flexGrow: 1,
                          marginRight: "20px",
                          backgroundImage: `url(${item.backgroundImg})`,
                          transition: "transform 0.3s ease-in-out",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <Box sx={{ paddingTop: "1rem", paddingLeft: "1rem" }}>
                          <Typography
                            fontWeight={"bold"}
                            sx={{
                              transition: "transform 0.3s ease-in-out",
                              "&:hover": {
                                transform: "scale(1.1)",
                              },
                              color: "black",
                            }}
                          >
                            {item.title.toUpperCase()}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ transition: "transform 0.3s ease-in-out" }}
                          >
                            {item.subtitle}
                          </Typography>
                          <Box sx={{ marginTop: "250px" }}>
                            <Button>
                              Explore
                              <NavigateNextIcon />
                            </Button>
                          </Box>
                        </Box>
                      </Box>

                      {/* <img
                    src={item.backgroundImg}
                    width={"100%"}
                    height={400}
                    style={{
                      marginBottom: "-0.3rem",
                      transition: "transform 0.3s ease-in-out",
                      objectFit: "cover",
                      transformOrigin: "center right",
                      position: "absolute",
                      right: "0",
                      bottom: "0",
                    }}
                    alt={item.title}
                  /> */}
                    </Paper>
                  </Grid>
                ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Home;
