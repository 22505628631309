import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/images/logo2.png";
import { useNavigate } from "react-router-dom";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import ReactFlagsSelect from "react-flags-select";
import ReactLanguageSelect from "react-languages-select";

import "react-languages-select/css/react-languages-select.css";
import { useMediaQuery } from "@mui/material";
// import SwipeableDrawer from "@mui/material/SwipeableDrawer";
// import List from "@mui/material/List";

// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";

import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import { AccountCircle } from "@mui/icons-material";
// import instagramIcon from "../../assets/images/instagram.png";
// import whatsAppIcon from "../../assets/images/whatsapp.png";
const menuItem = [
  {
    name: "We are Hiring",
    icon: <PersonSearchIcon />,
    path: "/",
  },
  {
    name: "Investor Relations",
    icon: <AttachMoneyIcon />,
    path: "/",
  },
  {
    name: "Advertisse",
    icon: <VolumeUpOutlinedIcon />,
    path: "/",
  },
  {
    name: "Free Listing",
    icon: <ShowChartOutlinedIcon />,
    path: "/",
  },
];

export default function Navbar() {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState("IN");
  const matches = useMediaQuery("(max-width:600px)");
  // const [open, setOpne] = React.useState(false);

  return (
    <Box>
      <AppBar
        elevation={1}
        sx={{
          backgroundColor: "white",
          //   padding: { sm: "0rem -1rem", md: "0rem 2rem" },
        }}
        position="fixed"
      >
        {/* <Box
          sx={{ display: "flex", justifyContent: "right", marginRight: "2rem" }}
        >
          <Box
            style={{
              margin: "0rem 1rem ",
              paddingTop: "1rem",
              paddingLeft: "0.2rem",
              paddingRight: "0.2rem",
              backgroundColor: "black",
              cursor: "pointer",
              borderBottomLeftRadius: "30px", // Set the radius for the bottom left corner
              borderBottomRightRadius: "30px",
            }}
          >
            <img src={whatsAppIcon} width={22} height={20} alt="whatsapp" />
          </Box>
          <Box
            style={{
              margin: "0rem 1rem ",
              paddingTop: "1rem",
              paddingLeft: "0.2rem",
              paddingRight: "0.2rem",
              backgroundColor: "black",
              cursor: "pointer",
              borderBottomLeftRadius: "30px", // Set the radius for the bottom left corner
              borderBottomRightRadius: "30px",
            }}
          >
            <img src={instagramIcon} width={22} height={20} alt="instagram" />
          </Box>
          <Box sx={{ display: { xs: "block", sm: "block", md: "none" } }}>
            <Box
              style={{
                margin: "0rem 1rem ",
                paddingTop: "1rem",
                paddingLeft: "0.2rem",
                paddingRight: "0.2rem",
                backgroundColor: "black",
                cursor: "pointer",
                borderBottomLeftRadius: "30px", // Set the radius for the bottom left corner
                borderBottomRightRadius: "30px",
              }}
            >
              <NotificationsActiveOutlinedIcon fontSize="small" />
            </Box>
          </Box>
        </Box> */}
        <Toolbar sx={{ padding: "0.5rem 0.5rem" }}>
          <Box
            // justifyContent={"center"}
            alignItems={"center"}
            display={"flex"}
            sx={{
              flexGrow: 1,
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                width: matches ? "70px" : "150px",
                height: "50px",
                objectFit: "contain",
                marginRight: "0.2rem",
              }}
            />
          </Box>
          <Box
            sx={{
              // flexGrow: "1",
              // display: "flex",
              color: "black",
              // mt: "0.2rem",
              // justifyContent: "center",
              // alignItems: "center",
            }}
          >
            <ReactFlagsSelect
              selected={selected}
              onSelect={(code) => setSelected(code)}
            />
          </Box>
          {/* <Box>language selector</Box> */}
          <Box
            color={"black"}
            sx={{
              cursor: "pointer",
            }}
            // fontWeight={"bold"}
            flexGrow={1}
            // gap={8}
          >
            <ReactLanguageSelect names={"international"} defaultLanguage="en" />
          </Box>
          <Box
          // sx={{ display: "flex" }}
          // justifyContent={"center"}
          // alignItems={"center"}
          // display={"flex"}
          >
            <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
              <IconButton>
                <NotificationsActiveOutlinedIcon sx={{ color: "black" }} />
              </IconButton>

              <Button
                onClick={() => navigate("/sign-up")}
                variant="contained"
                style={{
                  padding: "0.3rem 0.8rem",
                  textTransform: "unset",
                  // marginLeft: "1rem",
                }}
                color="primary"
              >
                login / Sign up
              </Button>
            </Box>
            <IconButton
              onClick={() => navigate("/sign-up")}
              sx={{ display: { xs: "block", sm: "block", md: "none" } }}
              size="small"
            >
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
