import { Container, Typography, Divider } from "@mui/material";
const PrivacyPolicy = () => {
  return (
    <Container style={{ marginTop: "2rem", marginBottom: "2rem" }}>
      <Typography variant="h5" gutterBottom>
        Privacy policy
      </Typography>
      <Divider />

      <section className="prisec">
        <h2>A. Introduction:</h2>
        <ul style={{ marginTop: 15, marginBottom: 20 }}>
          <li>
            We respect your privacy rights and recognize the importance of
            secure transactions and information privacy. This Privacy Policy
            describes how Pager-Ai Limited and its affiliates (collectively
            “Pager-Ai, we, our, us”) collect, use, share or otherwise process
            your personal information through Pager-Ai website www.pager-ai.com,
            its mobile application, and m-site (hereinafter referred to as the
            “Platform”).
          </li>
          <li>
            This Privacy Policy is an electronic agreement formed under the
            Information Technology Act, 2000 and the rules and regulations made
            there under (as amended till date) including the Information
            Technology (Reasonable security practices and procedures and
            sensitive personal data or information) Rules, 2011 &amp; the
            Information Technology (Intermediary Guidelines and Digital Media
            Ethics Code) Rules, 2021. It does not require any physical or
            digital signatures to make the terms of this policy binding. This
            privacy policy is a legally binding document. The terms of this
            privacy policy will be effective upon your use of our Platform
            /service. Please read this policy carefully, together with our Terms
            of Services .
          </li>
          <li>
            By visiting our Platform, providing your information or availing our
            product/service, you expressly agree to be bound by the terms of
            this Privacy Policy and the applicable Terms of service. While
            accessing or using our platform/services, you have given explicit
            consent to collect, use, share or otherwise process your information
            in accordance with this Privacy Policy. If you do not agree, please
            do not access or use our Platform or service.
          </li>
          <li>
            You acknowledge that you are disclosing Personal Information
            voluntarily. Prior to the completion of any registration process on
            our platform or prior to availing of any services offered on our
            platform if you wish not to disclose any Personal Information you
            may refrain from doing so; however if you don't provide information
            that is requested, it is possible that the registration process
            would be incomplete and/or you would not be able to avail of the our
            services. If you are our corporate customer, it is possible that we
            have entered into a contract with you for non-disclosure of
            confidential information. This Policy shall not affect such a
            contract in any manner.
          </li>
        </ul>
        <h2>B. Application of Policy:</h2>
        <ul style={{ marginTop: 15, marginBottom: 20 }}>
          <li>
            This policy governs each website, mobile site, application, and/or
            other service, regardless of how distributed, transmitted,
            published, or broadcast (“Service”) provided by Pager-Ai Limited
            and/or its affiliates (“we,” “us,” or “our” or “Pager-Ai”) that
            links to this policy, which is binding on all those who access,
            visit and/or use Pager-Ai’s Services, i.e., Listing Service, Search
            Plus Services, Pager-Ai Mart, Pager-Ai Pay, Pager-Ai Omni, Pager-Ai
            Xpert, Pager-Ai Shopping etc.,. This policy shall apply equally to
            all of our vendors, service providers, subcontractors, partners,
            agents, representatives, employees, and any other third parties.
            Pager-Ai undertakes to only collect and use personal data
            voluntarily provided by you. You are able to browse our platform
            without disclosing any personal information about them. However you
            have to provide limited personal data to us, as may be required, in
            order to register or to avail certain services from us.
          </li>
          <li>
            This policy applies to all contractors, suppliers, customers, users,
            vendors, sellers, partners, and other third party working on behalf
            of Pager-Ai or accessing or using our platforms/service. It also
            applies to the head office, branches office and other offices of
            Pager-Ai and all employees/officials of Pager-Ai. It applies to all
            data that Pager-Ai holds relating to identifiable individuals, even
            if that information technically falls outside of the Privacy &amp;
            Data Protection law. The said information includes, Names, address,
            mobile/telephone numbers, email Id of users and any other
            information collected or received by users while accessing or using
            our platforms/service.{" "}
          </li>
          <li>
            This Privacy Policy is part of Pager-Ai’s Terms of Service and
            covers the treatment of user information, including personally
            identifying information, obtained by Pager-Ai, including information
            obtained when you access the Pager-Ai platform, use the Pager-Ai
            Service or any other software provided by Pager-Ai. Unless otherwise
            stated explicitly, this Policy applies to Personal Information as
            disclosed on any of our Platform. This policy does not apply to the
            practices of organizations that we do not own or to people that we
            do not employ or manage.
          </li>
        </ul>
        <h2>C. Objectives of policy:</h2>
        <ul style={{ marginTop: 15, marginBottom: 20 }}>
          <li>
            Pager-Ai is required to collect &amp; use certain information about
            individuals, i.e., customers, suppliers/vendors, business contacts,
            employees, and the third parties with whom Pager-Ai has a business
            relationship or may need to contact. This policy describes, how Just
            Dial collects, receives, possesses, stores, deals or handle personal
            information including sensitive personal information about you and
            ensure that the same are available for review by you. The personal
            information must be collected, handled and stored to meet the data
            protection standards as well as legislative requirements. This
            policy ensures to comply with data protection law and follows the
            good practice and protects the rights of employees, customers,
            suppliers/vendors, business contacts, employees, and the third
            parties and how to stores and processes data and protects from the
            risks of data breach.
          </li>
        </ul>
        <h2>D. Collection of Information:</h2>
        <ul style={{ marginTop: 15, marginBottom: 20 }}>
          <li>
            In the course of carrying out its various functions and activities,
            Pager-Ai collects information from individuals &amp; third parties
            and generates a wide range of information which is stored at our
            platform. These information can take many different forms, such as,
            corporate records, financial records, legal records, contracts,
            letters received from third parties, personnel/employees records,
            invoices, completed application forms, contact lists, email
            communications and attachments, photos etc.,. Pager-Ai collects
            information in order to provide and continually improve its products
            &amp; services. The information may be collected online or offline.
            Regardless of the collection method, the same privacy protection
            shall apply to all data/information including personal information.
          </li>
          <li>
            We collect Personal Information from you in a variety of ways when
            you interact with us through our services, such as, installing,
            accessing, or using our Services; Create an account on our services;
            Request customer service or contact us; Conduct a transaction where
            we collect personal information, including when required by law and
            regulations; Submit a testimonial, rating or review, or other
            user-generated content that may be posted; Otherwise submit personal
            information to us along with any related content of the
            communication.
          </li>
          <li>
            In the course of registering for and availing various services we
            provide from time to time through our website www.pager-ai.com
            ("Website", telephone search, SMS and Apps) or any other medium in
            which Pager-Ai may provide services (collectively referred to as
            "the Platform") you may be required to give your name, residence
            address, workplace address, email address, date of birth,
            educational qualifications , Marital status , and similar Personal
            Information ("Personal Information") &amp; credit card/debit card ,
            and other payment instrument details. We, either through third party
            service provider or by our own, collect the Know-Your-Customer (KYC)
            related document from you. KYC related documents include but not
            limited to, driving licence, proof of possession of Aadhaar number,
            the Voter's Identity Card, Pan Card, GST, Passport, job card issued
            by NREGA (duly signed by an officer of the State Government and
            letter issued by the National Population Register containing details
            of name and address). If OVD (officially valid documents) furnished
            by you don’t have an updated address, then in addition to OVD, we
            may also collect the utility bill (electricity, telephone, post-paid
            mobile phone, piped gas, water bill) not more than two months old,
            property or Municipal tax receipt; pension or family pension payment
            orders (PPOs) issued to retired employees by Government Departments
            or Public Sector Undertakings, containing the address; letter issued
            by the Foreign Embassy or Mission in India (in case of foreigner),
            as prescribed by RBI (Reserve Bank of India) from time to time. If
            you are Sole Proprietary firms, we may collect the documents which
            include, (a) Registration certificate; (b) Certificate/licence
            issued by the municipal authorities under Shop and Establishment
            Act. (c) Sales and income tax returns. (d) GST certificate
            (provisional/final). (e) IEC (Importer Exporter Code) issued to the
            proprietary concern by the office of DGFT or Licence/certificate of
            practice issued in your firm’s name (in case of Importer /Exporter).
            (f) Income Tax Return (just the acknowledgement) in your firm’s
            name, duly authenticated/acknowledged by the Income Tax authorities.
            (g) Utility bills such as electricity, water, landline telephone
            bills, etc., or, the documents relating to proof of
            business/activity. We may also conduct verification and collect such
            other information and clarification as would be required to
            establish the existence of your firm, as per our satisfaction. If
            you are Legal Entities, we may collect the documents which includes,
            (a) Certificate of incorporation; (b) Memorandum and Articles of
            Association; (c) Permanent Account Number of the company; (d) Board
            resolution &amp; power of attorney granted to its managers, officers
            or employees to transact on its behalf or Documents relating to
            beneficial owner. If you are partnership firm, we may collect the
            documents which include, (a) the certified copies Registration
            certificate; Partnership deed; Permanent Account Number of the
            partnership firm and Documents relating to beneficial owner,
            managers, officers or employees. If you are Trust, we may collect
            the documents which include, (a) the certified copies Registration
            certificate; Trust deed; Permanent Account Number or Form No.60 of
            the trust and Documents relating to beneficial owner, managers,
            officers or employees. If you are unincorporated association or a
            body of individuals, we may collect the documents which include, (a)
            the certified copies Resolution of the managing body; Permanent
            Account Number or Form No.60 of the unincorporated association or a
            body of individuals and Documents relating to beneficial owner,
            managers, officers or employees. We, either through third party
            service provider or by our own, may also undertake Video based
            Customer Identification Process (V-CIP) in Compliance of KYC policy
            of RBI. The Personal Information is used for three general purposes:
            to customize the content you see, to fulfil your requests for
            certain services, and to contact you about our services via
            including but not limited to email's, sms’s and other means of
            communication. Where possible, we indicate which fields are required
            and which fields are optional. You always have the option to not
            provide information by choosing not to use a particular service,
            product or feature on our Platform. Pager-Ai collects the personal
            information voluntarily provided by you while accessing or using our
            platforms/service. Pager-Ai also collects data (on regular basis)
            from various sources such as newspaper, visiting cards, pamphlets,
            magazines and journals (including both free and pay), by appointment
            of data collectors, join free option made available on Pager-Ai
            platforms. The example of the data/information collected by us is
            given below:
          </li>
        </ul>
        <h2>Types of information Collected by Pager-Ai:</h2>
        <table
          border={1}
          cellPadding={1}
          cellSpacing={1}
          width="100%"
          style={{ margin: "20px 0" }}
        >
          <tbody>
            <tr>
              <th style={{ padding: "7px 15px", textAlign: "left" }}>
                Sr. No.
              </th>
              <th style={{ padding: "7px 15px", textAlign: "left" }}>
                Source of Information
              </th>
              <th style={{ padding: "7px 15px", textAlign: "left" }}>
                Nature of Information Collected
              </th>
            </tr>
            <tr>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                1){" "}
              </td>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                Users/Customers
              </td>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                <ol style={{ listStyleType: "lower-latin" }}>
                  <li>
                    Personal Identifying information such as name, address and
                    phone numbers; email Id, Age, personal description, profile
                    photograph etc., &amp; delivery address,
                  </li>
                  <li>payment information.</li>
                  <li>location information.</li>
                  <li>Device information (if you provided).</li>
                  <li>IP address.</li>
                  <li>
                    Name, addresses &amp; phone numbers, e-mail IDs of friends
                    and other people listed in Addresses;{" "}
                  </li>
                  <li>Content of reviews and e-mails to us.</li>
                  <li>voice recordings when you call to us.</li>
                  <li>
                    credit usage, login detail, device log files etc., while
                    using our platform.
                  </li>
                  <li>Contacts – address book for app users</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                2){" "}
              </td>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                Vendors/Sellers
              </td>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                <ol style={{ listStyleType: "lower-latin" }}>
                  <li>
                    Personal Identifying information such as name, address and
                    phone numbers; email Id, Age, personal description, profile
                    photograph ,Marital status etc.,.
                  </li>
                  <li>payment information.</li>
                  <li>location information.</li>
                  <li>Device information (if you provided)</li>
                  <li>IP address.</li>
                  <li>
                    Name, addresses &amp; phone numbers, e-mail IDs of friends
                    and other people listed in Addresses.
                  </li>
                  <li>content of reviews and e-mails to us.</li>
                  <li>voice recordings when you call to us.</li>
                  <li>
                    images, videos and other content collected or stored in
                    connection with our Services.
                  </li>
                  <li>
                    information and officially valid documents (KYC regarding
                    identity and address information, including mobile &amp;
                    landline number, place of business, valid Email id, vendor’s
                    photo, id &amp; address proof (such as Aadhar card, Pan
                    Card, GST Voter Id Card, Passport, Shop and Establishment
                    Certificate, etc.,.
                  </li>
                  <li>credit usage</li>
                  <li>corporate and financial information, and</li>
                  <li>device log files and configurations etc.,.</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                3){" "}
              </td>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                Automatic Information
              </td>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                <ol style={{ listStyleType: "lower-latin" }}>
                  <li>IP address of your device connected to our platform.</li>
                  <li>
                    Login details, e-mail address, and password, device log
                    files etc.,.
                  </li>
                  <li>location of device/computer.</li>
                  <li>
                    content interaction information, downloads, streaming of
                    video, network details etc.,.
                  </li>
                  <li>
                    device metrics, application usage, connectivity data, and
                    any errors or event failures.
                  </li>
                  <li>
                    our Services metrics, any technical errors, interactions
                    with our service features and content, settings preferences
                    and backup information, location of device, file name,
                    dates, times etc while using our service.
                  </li>
                  <li>content use history.</li>
                  <li>
                    URLs including date &amp; time; products &amp; contents
                    viewed or searched for; page response times, download
                    errors, length of visits to certain pages, and page
                    interaction information etc.,.
                  </li>
                  <li>phone numbers used to call to us.</li>
                  <li>Images/videos while visiting our platforms.</li>
                  <li>
                    device identifiers, cookies, browsing history, usage
                    history, and/or other technical information.
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                4){" "}
              </td>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                Information from Other Sources
              </td>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                <ol style={{ listStyleType: "lower-latin" }}>
                  <li>
                    updated delivery and address information from our carriers
                    or other third parties.
                  </li>
                  <li>
                    account information, purchase or redemption information and
                    page-view information from some merchants/partners for which
                    we provide technical, advertising or other services.
                  </li>
                  <li>
                    information about interactions with vendors while
                    interacting via Pager-Ai channel.
                  </li>
                  <li>
                    search results and links, including paid/free listings.
                  </li>
                  <li>internet-connected devices details.</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                5){" "}
              </td>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                Officials/Employees/Resellers etc...
              </td>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                <ol style={{ listStyleType: "lower-latin" }}>
                  <li>
                    Personal Identifying information such as name, address and
                    phone numbers; email Id, Age, personal description, profile
                    photograph etc.,.
                  </li>
                  <li>Educational Information.</li>
                  <li>
                    information and officially valid documents (KYC) regarding
                    identity and address information.
                  </li>
                  <li>payment information.</li>
                  <li>location information.</li>
                  <li>Device information (if you provided)</li>
                  <li>IP address.</li>
                  <li>content of reviews and e-mails to us.</li>
                  <li>voice recordings when you call to us.</li>
                  <li>
                    login detail, device log files etc., while using our
                    platforms.
                  </li>
                </ol>
              </td>
            </tr>
            <tr>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                6){" "}
              </td>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                Third Party Information
              </td>
              <td
                valign="top"
                style={{ padding: 15, backgroundColor: "#f9f9f9" }}
              >
                <ol style={{ listStyleType: "lower-latin" }}>
                  <li>
                    Corporate &amp; financial information about our co-branded
                    partners, delivery partners, and other third party
                    associated with us.
                  </li>
                  <li>CIN Number, PAN Number, GSTN Number etc.,.</li>
                  <li>Location information.</li>
                  <li>Device information (if you provided)</li>
                  <li>IP address.</li>
                  <li>Internet-connected devices details.</li>
                  <li>Identity and address information etc.,.</li>
                </ol>
              </td>
            </tr>
          </tbody>
        </table>
        <h2>E. Pager-Ai Doesn’t Collect the Information:</h2>
        <ul style={{ marginTop: 15, marginBottom: 20 }}>
          <li>
            Pager-Ai does not collect sensitive personal data or information. It
            only collects the sensitive personal data or information which is
            considered necessary to provide the service or to fulfil the purpose
            and for a lawful purpose connected with a function or activity of
            Pager-Ai. User can continue to browse / search on pager-ai’s
            platform without giving any information; however, PI is required for
            availing the certain services on pager-ai’s platform.
          </li>
          <li>
            Pager-Ai does not collect emails, addresses or other contact
            information from your mobile address book or contact lists other
            than name and mobile phone numbers. Pager-Ai does not store
            historical location information in any form or manner at its end,
            except for last known location from which Pager-Ai software was
            accessed in case of non-availability of current location data. Just
            Dial App integrates with face-book SDK and may send aggregate usage
            statistics to face-book from time to time. Usage of information sent
            to face-book is governed by Facebook's data usage policies which can
            be found at{" "}
          </li>
          <li>
            While collecting information, Pager-Ai ensures that you must be
            informed about
            <ol>
              <li>the fact that the information is being collected;</li>
              <li>
                the purpose for which the information is being collected;{" "}
              </li>
              <li>the intended recipients of the information; and</li>
              <li>
                the name and address of (i) the agency that is collecting the
                information; and (ii) the agency that will retain the
                information.
              </li>
            </ol>
          </li>
          <li>
            The data/information shall not be collected unless-
            <ol>
              <li>you have provided a valid, informed &amp; free consent; </li>
              <li>
                processing is necessary for the performance of a contract to
                which you are party;
              </li>
              <li>
                processing is necessary for compliance with the company legal
                obligation;
              </li>
              <li>
                processing is necessary in order to protect your interests; or
              </li>
              <li>
                processing is necessary for the performance of a task carried
                out in the public interest.
              </li>
            </ol>
          </li>
        </ul>
        <h2>F. Usage of Information:</h2>
        <ul style={{ marginTop: 15, marginBottom: 20 }}>
          <li>
            The data/information collected shall be used for the purpose for
            which it has been collected. In addition, the Pager-Ai uses the data
            for the following purposes:
            <ol>
              <li>To provide services.</li>
              <li>
                To fulfill your requests for products and services and
                communicate with you about those requests. If you search the
                products or service from our platform without logged in or
                sought the information by calling our hotline, we collect mobile
                number and generate lead with our vendors/service providers, as
                per your choice/request. If you search the products or service
                from our platform with logged in, we share your information
                (i.e., name, mobile, email etc.,) as available with us with our
                vendors/service providers to fulfil your choice/request.
              </li>
              <li>
                To administer contests, sweepstakes, promotions, and surveys.{" "}
              </li>
              <li>
                To respond to reviews, comments, or other feedback you provide
                us.{" "}
              </li>
              <li>
                To help us personalize our service offerings, websites, mobile
                services, and advertising.
              </li>
              <li>
                To provide functionality, analyze performance, fix errors, and
                improve the usability and effectiveness of the Services.
              </li>
              <li>
                To recommend features, products, and services that might be of
                interest to you, identify your preferences, and personalize your
                experience with Pager-Ai Services.
              </li>
              <li>
                To operate, provide, develop, and improve the products and
                services that we offer our customers.
              </li>
              <li>
                To provide our services and to support our core business
                functions. These include order or service fulfilment, internal
                business processes, marketing, authentication, loss and fraud
                prevention, public safety and legal functions.
              </li>
              <li>
                To display interest-based ads for features, products, and
                services that might be of interest to you.
              </li>
              <li>
                To prevent and detect fraud and abuse in order to protect the
                security of our customers, Pager-Ai, and others.
              </li>
              <li>Communicate with you.</li>
              <li>Contacting with you for promoting services.</li>
              <li>
                The User's Personal Data may be used for legal purposes by Just
                Dial in legal proceeding at the stages leading to possible legal
                action arising from improper use of Pager-Ai services.
              </li>
              <li>For operation and maintenance purposes.</li>
              <li>
                We use your voice input, images, videos, and other personal
                information to respond to your requests, provide the requested
                service to you, and improve our services.
              </li>
              <li>
                It helps us in operating, providing, improving, understanding,
                customizing, supporting, and marketing our Services.
              </li>
              <li>
                Comply with legal obligations. In certain cases, we collect and
                use your personal information to comply with laws. For instance,
                we collect from seller’s information regarding place of
                establishment and bank account information for identity
                verification and other purposes.
              </li>
            </ol>
          </li>
          <li>
            Personal Information will be kept confidential and will be used for
            our research, marketing, and strategic client analysis objectives
            and other internal business purposes only. We do not sell Personal
            Information. If you are a customer of our services, your Information
            shall be shared with our subscribers/advertisers and you shall be
            given consent to the same. Further, the subscribers / advertisers
            who are listed with us, may call you, based on the query or enquiry
            that you make with us, enquiring about any Product / Service or
            Product / Service of any subscriber / advertiser. If you are a
            vendor/seller/business entity availing our services, your
            information shall be shared with our users, customers, callers,
            subscribers/advertisers who are seeking our services. You are hereby
            giving us the consent to share the details.
          </li>
          <li>
            We use your personal information to assist vendors, service
            providers, and business partners in handling and fulfilling orders;
            enhancing customer experience; resolve disputes; troubleshoot
            problems; help promote a safe service; collect money; measure
            consumer interest in our products/services; inform you about offers,
            products, services, and updates; customize and enhance your
            experience; detect and protect us against error, fraud and other
            criminal activity; enforce our terms and conditions; and as
            otherwise described to you at the time of collection of information.
          </li>
          <li>
            With your consent, we will have access to your SMS, contacts in your
            directory, location and device information. We may also request you
            to provide your PAN, GST Number, Government issued ID cards/number
            and Know-Your-Customer (KYC) details to: (i) check your eligibility
            for certain products and services including but not limited to
            credit and payment products; (ii) issue GST invoice for the products
            and services purchased for your business requirements; (iii) enhance
            your experience on the Platform and provide you access to the
            products and services being offered by us, vendors, sellers, service
            providers, affiliates or lending partners. You understand that your
            access to these products/services may be affected in the event
            consent is not provided to us.
          </li>
          <li>
            In our efforts to continually improve our product and service
            offerings, we and our affiliates collect and analyse demographic and
            profile data about our users' activity on our Platform. We identify
            and use your IP address to help diagnose problems with our server,
            and to administer our Platform. Your IP address is also used to help
            identify you and to gather broad demographic information.
          </li>
          <li>
            We will occasionally ask you to participate in optional surveys
            conducted either by us or through a third party market research
            agency. These surveys may ask you for personal information, contact
            information, date of birth, demographic information (like zip code,
            age, or income level), attributes such as your interests, household
            or lifestyle information, your purchasing behaviour or history,
            preferences, and other such information that you may choose to
            provide. The surveys may involve collection of voice data or video
            recordings, the participation of which would purely be voluntary in
            nature. We use this data to tailor your experience at our Platform,
            providing you with content that we think you might be interested in
            and to display content according to your preferences.{" "}
          </li>
          <li>
            We have arrangement with other companies and individuals to perform
            functions for examples include analysing data, providing marketing
            assistance, providing search results and links (including paid
            listings and links), processing credit card payments and providing
            customer service etc. They have access to personal information
            needed to perform their functions, but may not use it for other
            purposes. Further, they must process the personal information in
            accordance with this Privacy Policy and as permitted by applicable
            law. In certain cases, we collect and use your personal information
            to comply with laws. For instance, we collect from vendors/seller’s
            information regarding place of establishment and bank account
            information for identity verification and other purposes.
          </li>
          <li>
            If you submit Personally Identifiable Information to us through the
            pager-ai platform, or pager-ai Service, then we use your personal
            information to operate, maintain, and provide to you the features
            and functionality of the pager-ai platform and pager-ai Service. In
            particular, your mobile phone number is essential to your use of the
            pager-ai Service and will be retained. Your name (as it is saved in
            other user's mobile phone address book or contact list) may be
            displayed to other users if they tag your mobile number and access
            your ratings in the App.{" "}
          </li>
          <li>
            Pager-Ai "tags" the contacts in your address book as your friends,
            but at any-time, you can review the list of your tagged friends and
            choose to untag any of them at any time. Untagging your contacts
            will hide your ratings from them and will also result in their
            ratings not being shown to you. Ratings would not indicate your
            relationship with the reviewer however ratings will be always shown
            on the platform.{" "}
          </li>
          <li>
            Only users of Pager-Ai will be displayed in your tagged friends
            list. If a contact in your address book has rated an establishment/s
            on Pager-Ai platforms, the ratings associated with the contact will
            be displayed as part of your friends' ratings. If a contact in your
            address book has used Pager-Ai's service across any platform and has
            not rated an establishment, although their name is displayed in your
            friends list, no ratings will be shown associated with the said
            contact. If any of your tagged friends registers with our Service
            across Pager-Ai's platforms, ratings of such a friend will be
            visible to you only if Your mobile number is present in his contacts
            list and You have not been untagged by your friend. It will be
            indicated as friend however rating will be always visible to
            everyone as a common user of pager-ai{" "}
          </li>
          <li>
            We do not use your mobile phone number or other Personally
            Identifiable Information to send commercial or marketing messages
            without your consent or except as part of a specific program or
            feature for which you will have the ability to opt-in or opt-out. We
            may use cookies and log file information to (a) remember information
            so that you will not have to re-enter it during your re-visit on our
            platform; (b) provide custom, personalized content and information;
            (c) monitoring or pages viewed, etc.; and (d) track your entries,
            submissions, views etc.,.
          </li>
        </ul>
        <h2>G. Processing of Personal Information: </h2>
        <ul style={{ marginTop: 15, marginBottom: 20 }}>
          <li>
            Personal /Sensitive personal data may be processed if such
            processing is necessary, for any function of Parliament or any State
            Legislature and/or for the exercise of any function of the State
            authorised by law for the provision of any service or benefit to the
            data principal from the State; or the issuance of any certification,
            license or permit for any action or activity of the data principal
            by the State.
          </li>
          <li>
            Personal data/Sensitive personal data may be processed if such
            processing is (a) explicitly mandated under any law made by
            Parliament or any State Legislature; or (b) for compliance with any
            order or judgment of any Court or Tribunal in India.
          </li>
          <li>
            Personal data/Sensitive personal data may be processed if such
            processing is necessary— (a) to respond to any medical emergency
            involving a threat to the life or a severe threat to the health of
            the data principal or any other individual; (b) to undertake any
            measure to provide medical treatment or health services to any
            individual during an epidemic, outbreak of disease or any other
            threat to public health; or (c) to undertake any measure to ensure
            safety of, or provide assistance or services to, any individual
            during any disaster or any breakdown of public order.
          </li>
          <li>
            Personal data may be processed if such processing is necessary for—
            (a) recruitment or termination of employment of a data principal;
            (b) provision of any service to, or benefit sought by, the data
            principal; (c) verifying the attendance of the data principal; or
            (d) any other activity relating to the assessment of the performance
            of the data principal.
          </li>
          <li>
            In addition to the grounds for processing contained aforesaid,
            personal data may be processed if such processing is necessary for
            such reasonable purposes as may be specified after taking into
            consideration— (a) the interest of Pager-Ai in processing for that
            purpose; (b) whether Pager-Ai can reasonably be expected to obtain
            the consent of the data principal; (c) any public interest in
            processing for that purpose; (d) the effect of the processing
            activity on the rights of the data principal; and (e) the reasonable
            expectations of the data principal having regard to the context of
            the processing.
          </li>
          <li>
            The reasonable purposes means and includes, prevention and detection
            of any unlawful activity including fraud; whistle blowing; mergers
            and acquisitions; network and information security; credit scoring;
            recovery of debt; processing of publicly available personal data.
            However Pager-Ai ensures the protection of the rights of data
            principals.{" "}
          </li>
          <li>
            Sensitive personal data may be processed on the basis of explicit
            consent. [Consent shall be considered explicit only if it is valid
            if: (a) informed, having regard to whether the attention of the data
            principal has been drawn to purposes of or operations in processing
            that may have significant consequences for the data principal; (b)
            clear, having regard to whether it is meaningful without recourse to
            inference from conduct in a context; and (c) specific, having regard
            to whether the data principal is given the choice of separately
            consenting to the purposes of, operations in, and the use of
            different categories of sensitive personal data relevant to
            processing].
          </li>
          <li>
            <b>Processing of personal data of children:</b> Pager-Ai doesn’t
            allow children below the age of 13 years to visit our platform
            without parental guidance or control. Based on your date of birth
            disclosed by you, we verify your age. When the children visit our
            platform or use our service, we believe that they access our
            platform or service under the parental guidance, control and consent
            and we can process the information accordingly. Pager-Ai shall
            process personal data of children in a manner that protects the
            rights and best interests of the child.
          </li>
        </ul>
        <h2>H. Disclosure of Information:</h2>
        <ul style={{ marginTop: 15, marginBottom: 20 }}>
          <li>
            Other users of Pager-Ai Service may see your ratings submissions in
            a way that is consistent with the use of the Pager-Ai Service. For
            example, a rating of "ABC Restaurant" on Pager-Ai will be available
            to every user of the Pager-Ai Service who has that mobile phone
            number in their mobile phone's address book or contact list and has
            tagged the mobile phone number as a friend in the Pager-Ai App
            friends ratings feature. For more information on this feature,
            please refer paragraphs above.
          </li>
          <li>
            We do not sell or share your Personally Identifiable Information
            (such as mobile phone number) with other third-party companies for
            their commercial or marketing use without your consent or except as
            part of a specific program or feature for which you will have the
            ability to opt-in or opt-out. We may share your Personally
            Identifiable Information with third party service providers to the
            extent that it is reasonably necessary to perform, improve or
            maintain the Pager-Ai Service.
          </li>
          <li>
            We may disclose your personal information to third parties, such as
            vendors, sellers, service providers, business partners and other
            stakeholders. This disclosure may be required for us to provide you
            access to our products and services; for fulfilment of your orders;
            for enhancing your experience; for providing feedback on products;
            to collect payments from you; to comply with our legal obligations;
            to conduct market research or surveys; to enforce our Terms of Use;
            to facilitate our marketing and advertising activities; to analyse
            data; for customer service assistance; to prevent, detect, mitigate,
            and investigate fraudulent or illegal activities related to our
            product and services, sending postal mail and e-mail, removing
            repetitive information from customer lists, analyzing data and links
            (including paid listings and links), processing payments,
            transmitting content, scoring, assessing and managing credit risk,
            and providing customer service. We do not disclose your personal
            information to third parties without your explicit consent. These
            third-party service providers may have access to personal
            information needed to perform their functions, but may not use it
            for other purposes. Further, they must process the personal
            information in accordance with applicable law.
          </li>
          <li>
            We share information with our affiliated companies, business
            partners, associates etc with whom we are having an arrangement to
            provide/receive services. We may use the information we receive from
            them, and they may use the information we share with them, to help
            operate, provide, improve, understand, customize, support, and
            market our Services and their offerings. This includes helping
            improve infrastructure and delivery systems, understanding how our
            Services or theirs are used, securing systems, and fighting spam,
            abuse, or infringement activities and promoting, marketing of
            business.
          </li>
          <li>
            As we continue to develop our business, we might sell or buy other
            businesses or services. In such cases, all of our rights and
            obligations under our Privacy Policy are freely assignable by us to
            any of our affiliates, in connection with a merger, acquisition,
            restructuring, or sale of assets, or by operation of law or
            otherwise, and we may transfer your information to any of our
            affiliates, successor entities, or third party while providing the
            services subject to the pre-existing Privacy policy.
          </li>
          <li>
            We may disclose personal information if required to do so by law or
            in the good faith belief that such disclosure is reasonably
            necessary to: (a) respond to subpoenas, court orders, or other legal
            process or to government/ law enforcement agencies requests; (b)
            enforce our Terms and any other applicable terms and policies,
            including for investigations of potential violations; (c) detect,
            investigate, prevent, and address fraud and other illegal activity,
            security, or technical issues; or (d) protect the rights, property,
            and safety of our users, companies, or others. (e) for purpose of
            verification of identity, or for prevention, detection,
            investigation including cyber incidents, prosecution, and punishment
            of offences.
          </li>
          <li>
            We disclose the account and other personal information when we
            believe such disclosure is appropriate to comply with the law;
            enforce or apply our Terms of Use and other agreements; or protect
            the rights, property, or safety of Pager-Ai, our users, or others.
            This includes exchanging information with other companies and
            organizations for fraud protection.
          </li>
          <li>
            Pager-Ai shall share/disclose the data/information, without
            obtaining prior consent from You, with Government agencies mandated
            under the law to obtain information including sensitive personal
            data or information for the purpose of verification of identity, or
            for prevention, detection, investigation including cyber incidents,
            prosecution, and punishment of offences.{" "}
          </li>
        </ul>
        <h2>I. Transfer of information: </h2>
        <ul style={{ listStyleType: 1 }}>
          <li>
            {" "}
            Pager-Ai shall not transfer sensitive personal data or information
            including any information, to third parties in India, or outside
            India, except under the following circumstances:
            <ol style={{ listStyleType: "lower-latin" }}>
              <li>To fulfill your requests for products and services</li>
              <li>
                To fix errors, and improve the usability and effectiveness of
                the Services
              </li>
              <li>To fraud prevention, public safety and legal functions</li>
              <li>
                To prevent and detect fraud and abuse in order to protect the
                security of our customers, Pager-Ai, and others
              </li>
              <li>
                It is necessary for the performance of the lawful contract
                between Pager-Ai and data principal; or{" "}
              </li>
              <li>where data principal has consented to data transfer;</li>
            </ol>
          </li>
          <li>
            {" "}
            However Pager-Ai ensures the same level of data protection that is
            adhered to by them as that of Pager-Ai as provided for.
          </li>
          <li>
            {" "}
            When using vendors to collect personal information on the behalf of
            Pager-Ai, it shall ensure that the vendors comply with the data
            protection requirements of Pager-Ai as defined in this Policy.
          </li>
        </ul>
        <h2>J. Retention of Your Information: </h2>
        <ul style={{ listStyleType: 1 }}>
          <li>
            The Personal information may only be used for the purposes
            identified in the TOS and only if you have given consent. Personal
            information shall be retained for as long as necessary for business
            purposes identified in the TOS at the time of collection or
            subsequently authorized by you. We retain your personal information
            in accordance with applicable laws, for a period no longer than is
            required for the purpose for which it was collected or as required
            under any applicable law. However, we may retain data related to you
            if we believe it may be necessary to prevent fraud or future abuse
            or if required by law or for other legitimate purposes. We may
            continue to retain your data in anonymised form for analytical and
            research purposes.
          </li>
          <li>
            When the use of personal information is no longer necessary for
            business purposes, a method shall be in place to ensure that the
            information is destroyed in a manner sufficient to prevent
            unauthorized access to that information or is de-identified in a
            manner sufficient to make the data non-personally identifiable. Just
            Dial shall have a documented process to communicate changes in
            retention periods of personal information required by the business
            to you who are authorized to request those changes.
          </li>
          <li>
            Pager-Ai holding sensitive personal data or information shall not
            retain that information for longer than is required for the purposes
            for which the information may lawfully be used or is otherwise
            required under any other law for the time being in force. Pager-Ai
            has framed the data retention policy and it retains all sorts of
            data/information including sensitive personal data or information in
            accordance with data retention policy. The data/information shall be
            de-identified/destroyed, when the purposes of data collection can be
            achieved, in accordance with our data retention policy.
          </li>
          <li>
            Personal information shall be erased if their storage violates any
            of the data protection law or if knowledge of the data is no longer
            required by Pager-Ai or for the benefit of the data providers.
            Additionally, Pager-Ai has the right to retain the personnel
            information for legal and regulatory purpose as per applicable laws.
            Pager-Ai shall perform an internal audit on an annual basis to
            ensure that personal information collected is used, retained and
            disposed-off in compliance with the applicable law.
          </li>
        </ul>
        <h2>K. Review of Information by You: </h2>
        <ol style={{ listStyleType: 1 }}>
          <li>
            Pager-Ai shall establish &amp; facilitates a mechanism to enable you
            to review the data/information you had provided, as per your choice,
            and shall access, edit, rectify, modify, delete any personal
            information or sensitive personal information found to be inaccurate
            or deficient. However Pager-Ai shall not be responsible for the
            authenticity of the personal information or sensitive personal
            information supplied by you.
          </li>
          <li>
            You shall be entitled to obtain the details about your personal
            information upon a request made and set forth in writing. Pager-Ai
            shall provide its response within 72 (seventy two) hours of receipt
            of written request. You shall have the right to require Pager-Ai to
            correct or supplement erroneous, misleading, outdated, or incomplete
            personal information. We shall record &amp; document each access
            request as it is received and the corresponding action taken. Just
            Dial shall provide personal information to you in a plain simple
            format which is understandable.
          </li>
        </ol>
        <h2>L. Your Consent (Opt-in &amp; Opt-out):</h2>
        <ul style={{ listStyleType: 1 }}>
          <li>
            By visiting our Platform or by providing your information, you
            consent to the collection, use, storage, disclosure and otherwise
            processing of your information (including sensitive personal
            information) on our Platform in accordance with this Privacy Policy.
            If you disclose to us any personal information relating to other
            people, you represent that you have the authority to do so and to
            permit us to use the information in accordance with this Privacy
            Policy. You, while providing your personal information over our
            Platform or any of our partner Platform or establishments, consent
            to us (including our affiliates, lending partners, technology
            partners, marketing channels, business partners, vendors, service
            providers and other third parties) to contact you through SMS,
            instant messaging apps, call and/or e-mail for the purposes
            specified in this Privacy Policy.
          </li>
          <li>
            If you want to manage, change, or edit your information, we allow
            you to do so through the Settings tools: You can change your
            settings to manage certain information available to other users. You
            can manage your contacts, groups, and broadcast lists, or use our
            block feature to manage the users with whom you communicate.
          </li>
          <li>
            If you are vendors, you can add or update certain information in
            vendor’s module, update your account information by accessing your
            vendors Account Information, and adjust your e-mail or other
            communications you receive from us by updating your Notification
            Preferences.
          </li>
          <li>
            You can add or update your Personal Information on regular basis.
            Pager-Ai would retain your previous Personal Information in its
            records.
          </li>
          <li>
            You may, of course, decline to submit Personally Identifiable
            Information through the Pager-Ai Service, in which case Pager-Ai may
            not be able to provide certain services to you. If you do not agree
            with our Privacy Policy or Terms of Service, please delete your
            account and uninstall the Pager-Ai mobile application; your
            continued usage of the Pager-Ai Service will signify your assent to
            and acceptance of our Privacy Policy and Terms of Service. To
            protect your privacy and security, we take reasonable steps (such as
            SMS authentication in certain cases) to verify your identity before
            registering your mobile phone number and granting you access to the
            Pager-Ai Service. Please contact Pager-Ai via email to privacy at
            pager-ai.com or available web forms with any questions or comments
            about this Privacy Policy, your personal information, your consent,
            or your opt-in or opt-out choices.
          </li>
          <li>
            Pager-Ai shall, prior to the collection of information including
            sensitive personal data or information, provide an option to you to
            not to provide the data or information sought to be collected. Just
            Dial shall establish systems for the collection and documentation of
            Your consent to the collection, processing, and/or transfer of
            personal data. You shall be informed about the choices available to
            them with respect to the collection, use, and disclosure of personal
            information.{" "}
          </li>
          <li>
            Further, Pager-Ai shall establish &amp; facilitates a mechanism to
            enable You to, at any time while availing the services or otherwise,
            to withdraw its consent given earlier to Pager-Ai. If You not
            providing the consent or later on withdrawing your consent, Just
            Dial reserves its right not to provide goods/services for which the
            said information was sought. Consent shall be obtained (in
            writing/electronically) from You before or at the time of collecting
            personal information or as soon as practical thereafter.
          </li>
          <li>
            The changes to your preferences shall be managed and documented.
            Consent or withdrawal of consent shall be documented appropriately.
            The choices shall be implemented in a timely fashion and respected.
            If personal information is to be used for purposes not identified in
            the TOS at the time of collection, the new purpose shall be
            documented, You shall be notified, and consent shall be obtained
            prior to such new use or purpose. You shall be notified if the data
            collected is used for marketing purposes, advertisements, etc.,.
            Pager-Ai shall review the policies of the Third Parties and types of
            consent of Third Parties before accepting personal information from
            Third-Party data sources.
          </li>
        </ul>
        <h2>M. Consent to Push Notification:</h2>
        <ul style={{ listStyleType: 1 }}>
          <li>
            You agree and confirm that any review, rating and comments,
            including any other content or data therein, that you submit/post on
            Pager-Ai platforms viz. WEB, WAP, APP &amp; Phone etc. such details
            as per Pager-Ai's discretion will be shared with your Tagged Friends
            who are the users of Pager-Ai's services.
          </li>
        </ul>
        <h2>N. Third-party Advertisers, Links to Other Sites:</h2>
        <ul style={{ listStyleType: 1 }}>
          <li>
            pager-ai is currently ad-free and we hope to keep it that way
            forever. We have no intention to introduce advertisement into the
            product, but if we ever do, we will update this section.
          </li>
          <li>
            If you use Pager-Ai services of our Partners, you will be redirected
            to Partners' websites/applications and your entry to Partners
            websites/applications will be based on your Pager-Ai login
            credentials after seeking your permissions to share the data
            further. Pager-Ai shall not be responsible for its Partner’s privacy
            practices or the content of their privacy policies, and we request
            you to read their privacy policies prior to disclosing any
            information. If you pay using our Services, we may receive
            information and confirmations, such as payment receipts, from app
            stores or other third parties processing your payment.
          </li>
        </ul>
        <h2>O. Children Information</h2>
        <ul style={{ listStyleType: 1 }}>
          <li>
            We do not knowingly solicit or collect personal information from
            children under the age of 13 and use of our Platform is available
            only to persons who can form a legally binding contract under the
            Indian Contract Act, 1872. If you are under the age of 18 years then
            you must use our Platform or services under the supervision of your
            parent, legal guardian or any responsible adult.
          </li>
        </ul>
        <h2>P. Our Commitment to Information Security:</h2>
        <ul style={{ listStyleType: 1 }}>
          <li>
            The security of your Personal Information is important to us. You
            are responsible for keeping your device and your account safe and
            secure, and you must notify us promptly of any unauthorized use or
            security breach of your account or our Services. Your account
            information is protected by the password you use to access your
            online account. Please keep this password confidential. Pager-Ai
            uses commercially reasonable physical, managerial, and technical,
            electronic and procedural safeguards to preserve the integrity and
            security of your personal information. Pager-Ai shall adopt
            reasonable security practices and procedures as mandated under
            applicable laws for the protection of your Information. For this
            purpose, Pager-Ai adopts internal reviews of the data collection,
            storage and processing practices and security measures, including
            appropriate encryption and physical security measures to guard
            against unauthorized access to systems where Pager-Ai stores your
            Personal Information. We cannot, however, ensure or warrant the
            security of any information you transmit to Pager-Ai and you do so
            at your own risk and you hereby waive and release Pager-Ai from any
            claim of damages under contract.
          </li>
          <li>
            Pager-Ai may share your Information with third parties under a
            confidentiality agreement which inter-alia provides for that such
            third parties not disclosing the Information further unless such
            disclosure is for the purpose. However, Pager-Ai is not responsible
            for any breach of security or for any actions of any third parties
            that receive your Personal Information. Pager-Ai is not liable for
            any loss or injury caused to you as a result of you providing your
            Personal Information to third party (including any third party
            websites, even if links to such third party websites are provided on
            the Website). Notwithstanding anything contained in this Policy or
            elsewhere, Pager-Ai shall not be held responsible for any loss,
            damage or misuse of your Personal Information, if such loss, damage
            or misuse is attributable to a Force Majeure Event.{" "}
            <b>
              A "Force Majeure Event" shall mean any event that is beyond the
              reasonable control of Pager-Ai and shall include, without
              limitation, sabotage, fire, flood, explosion, acts of God, civil
              commotion, strikes or industrial action of any kind, riots,
              insurrection, war, acts of government, computer hacking,
              unauthorized access to computer, computer system or computer
              network, computer crashes, breach of security and encryption
              (provided beyond reasonable control of Pager-Ai), power or
              electricity failure or unavailability of adequate power or
              electricity.
            </b>{" "}
            While we will endeavour to take all reasonable and appropriate steps
            to keep secure any Personal Information which we hold about you and
            prevent unauthorized access. You acknowledge that the internet or
            computer networks are not fully secure and that we cannot provide
            any absolute assurance regarding the security of your Personal
            Information. We maintain physical, electronic, and procedural
            safeguards in connection with the collection, storage, processing,
            and disclosure of personal information. Our security procedures mean
            that we may occasionally request proof of identity before we
            disclose personal information to you.
          </li>
          <li>
            We maintain reasonable physical, electronic and procedural
            safeguards to protect your information. Whenever you access your
            account information, we offer the use of a secure server. Once your
            information is in our possession we adhere to our security
            guidelines to protect it against unauthorized access. However, by
            using our platform, you accept the inherent security implications of
            data transmission over the internet which cannot always be
            guaranteed as completely secure, and therefore, there would always
            remain certain inherent risks regarding use of the Platform. You are
            responsible for ensuring the protection of login &amp; password
            records for their account. You hereby waive and release Pager-Ai
            from any claim of damages under contract.
          </li>
          <li>
            We work to protect the security of your data/information including
            sensitive personal data during transmission by using encryption
            protocols and software. We follow the Payment Card Industry Data
            Security Standard (PCI DSS) when handling payment card data. If you
            choose a payment gateway to complete any transaction on Pager-Ai
            Platform then your credit/debit card data/financial details may be
            stored in compliance with industry standards/ recommended data
            security standard for security of financial information, i.e., the
            Payment Card Industry Data Security Standard (PCI-DSS) and as per
            applicable law.
          </li>
          <li>
            Pager-Ai shall review the policies and collection methods of
            Third-Parties before accepting personal information from Third-Party
            data sources. The audit of reasonable security practices and
            procedures shall be carried cut by an auditor annually undertake
            significant upgradation of its process and computer resource.
          </li>
        </ul>
        <h2>Q. Transparency &amp; Accountability Measures:</h2>
        <ol style={{ listStyleType: "lower-latin" }}>
          <li>
            <b>Privacy by Design:</b>
            <ul style={{ listStyleType: 1 }}>
              <li>
                Pager-Ai shall implement policies and measures to ensure that
                managerial, organisational, business practices and technical
                systems are designed in a manner to anticipate, identify and
                avoid harm to you;{" "}
              </li>
              <li>
                Pager-Ai shall implement policies and measures to ensure that
                the technology used in the processing of personal data is in
                accordance with commercially accepted or certified standards;
              </li>
              <li>
                Pager-Ai shall implement policies and measures to ensure that
                the legitimate interest of businesses including any innovation
                is achieved without compromising privacy interests;
              </li>
              <li>
                Pager-Ai shall implement policies and measures to ensure that
                privacy is protected throughout processing from the point of
                collection to deletion of personal data and processing of
                personal data is carried out in a transparent manner and the
                interest of the data provider is accounted for at every stage of
                processing of personal Data.
              </li>
            </ul>
          </li>
          <li>
            <b>Transparency:</b> Pager-Ai shall take reasonable steps to
            maintain transparency regarding its general practices related to
            processing personal data and shall make the following information
            available in an easily accessible form as may be specified -
            <ul style={{ listStyleType: "circle" }}>
              <li>
                the categories of personal data generally collected and the
                manner of such collection;
              </li>
              <li>
                the purposes for which personal data is generally processed;
              </li>
              <li>
                any categories of personal data processed in exceptional
                situations or any exceptional purposes of processing that create
                a risk of significant harm;
              </li>
              <li>
                the existence of and procedure for the exercise of data
                principal rights;
              </li>
              <li>
                the existence of a right to file complaints to the Authority;
              </li>
              <li>
                Information regarding cross-border transfers of personal data,
                if any.
              </li>
            </ul>
          </li>
          <li>
            <b>Security Safeguards:</b> Having regard to the nature, scope and
            purpose of processing personal data undertaken, the risks associated
            with such processing, and the likelihood and severity of the harm
            that may result from such processing, Pager-Ai shall implement
            appropriate security safeguards including (a) use of methods such as
            de-identification and encryption; (b) steps necessary to protect the
            integrity of personal data; and (c) steps necessary to prevent
            misuse, unauthorised access to, modification, disclosure or
            destruction of personal data. Pager-Ai shall undertake a review of
            its security safeguards periodically as may be specified and may
            take appropriate measures accordingly.{" "}
          </li>
          <li>
            <b>Personal Data Breach:</b> Pager-Ai shall notify the Authority of
            any personal data breach relating to any personal data processed by
            Pager-Ai where such breach is likely to cause harm to any data
            principal. The said notification includes, (a) nature of personal
            data which is the subject matter of the breach; (b) number of data
            principals affected by the breach; (c) possible consequences of the
            breach; and (d) measures being taken by the data fiduciary to remedy
            the breach.
          </li>
          <li>
            <b>Record-Keeping:</b> Pager-Ai shall maintain accurate and
            up-to-date records of the following: (a) important operations in the
            data life-cycle including collection, transfers, and erasure of
            personal data to demonstrate compliance; (b) periodic review of
            security safeguards; (c) data protection impact assessments;{" "}
          </li>
          <li>
            <b>Data Audits: </b>Pager-Ai shall have its policies and the conduct
            of its processing of personal data audited annually by an
            independent data auditor. The data auditor will evaluate the
            compliance of Pager-Ai with the provisions of law including (a)
            clarity and effectiveness of notices, transparency, security
            safeguards, instances of personal data breach and response thereto.
          </li>
        </ol>
        <h2>R. Monitoring and Enforcement:</h2>
        <ol style={{ listStyleType: 1 }}>
          <li>
            <b>Dispute Resolution &amp; Recourse:</b> Pager-Ai shall define and
            document a cyber-incident management program which addresses the
            data protection &amp; privacy related incidents. The incident
            management program includes a clear escalation path up to the
            executive management, legal counsel, and the board based on type
            and/or severity of the data breach. It shall define a process to
            register all the incidents/complaints and queries related to data
            breach. Pager-Ai shall perform a periodic review of all the
            complaints related to data breach to ensure that all the complaints
            are resolved in a timely manner and resolutions are documented and
            communicated to the data principals. An escalation process for
            unresolved complaints and disputes which shall be designed and
            documented and the Communication of privacy incident / breach
            reporting channels and the escalation matrix shall be provided to
            all data providers.
          </li>
          <li>
            <b>Dispute Resolution &amp; Escalation Process for Employees: </b>
            Employees with enquiries or complaints about the processing of their
            personal information shall first discuss the matter with their
            immediate reporting manager. If the employee does not wish to raise
            an inquiry or complaint with their immediate reporting manager, or
            if the manager and employee are unable to reach a satisfactory
            resolution of the issues raised, the employee shall bring the issue
            to the attention of the Grievance Officer.
          </li>
          <li>
            <b>
              Dispute Resolution &amp; Escalation Process for Customer / Third
              Party:{" "}
            </b>
            Customers / Third Party with inquiries or complaints about the
            processing of their personal information shall bring the matter to
            the attention of the Grievance Officer in writing. Any disputes
            concerning the processing of the personal information of
            non-employees shall be resolved through arbitration.
          </li>
        </ol>
        <h2>S. Terms of Use, Policy &amp; Revisions:</h2>
        <ol style={{ listStyleType: 1 }}>
          <li>
            If you choose to use Pager-Ai Services, your use and any dispute
            over privacy is to this policy and our Terms of Use, including
            limitations on damages, resolution of disputes, and application of
            the prevailing law in India. If you have any concern about privacy
            at Pager-Ai, please contact us with a thorough description, and we
            will try to resolve it. Our business changes constantly and our
            Privacy policy will change also. You should check our websites
            frequently to see recent changes.
          </li>
          <li>
            Unless stated otherwise, our current Privacy Policy applies to all
            information that we have about you and your account. We assure that
            will never materially change our policies and practices to make them
            less protective of your information collected in the past without
            your consent.
          </li>
        </ol>
        <h2>T. Grievance Officer:</h2>
        <p>
          1. In accordance with Information Technology Act 2000 and rules &amp;
          regulations made thereunder, the name and contact details of the
          Grievance Officer are provided below:
        </p>
        <p>
          Name :Mr. Vikas Gupta <br />
          Address: A1QA (OPC) PRIVATE LIMITED HD-192 WeWork Latitude, 9th Floor
          RMZ Latitude Commercial Bellary Road, Hebbal Near Godrej Apt
          Bengaluru, Karnataka 560024.
          <br />
          Contact No: <a href="tel:8875668866"> 8875668866 </a> <br />
          Email: <a href="mailto:legal@pager-ai.com">legal@pager-ai.com</a>
        </p>
        <p>
          2. If you have a query, issue, concern, or complaint in relation to
          collection or usage of your personal information under this Privacy
          Policy, please contact us at the contact information provided above or
          write to us at&nbsp;
          <a href="mailto:legal@pager-ai.com">legal@pager-ai.com.</a>
        </p>
        <h2>DEFINITONS: </h2>
        <table cellSpacing={0} cellPadding={0} border={1}>
          <tbody>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>"Act"</td>{" "}
              <td style={{ padding: 15 }}>
                "Act" means the Information Technology Act, 2000 (21 of 2000);
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>
                "Automated means"
              </td>{" "}
              <td style={{ padding: 15 }}>
                "Automated means" means any equipment capable of operating
                automatically in response to instructions given for the purpose
                of processing data.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>"Biometrics"</td>{" "}
              <td style={{ padding: 15 }}>
                "Biometrics" means the technologies that measure and analyse
                human body characteristics, such as 'fingerprints', 'eye retinas
                and irises', 'voice patterns', "facial patterns', 'hand
                measurements' and 'DNA' for authentication purposes.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>
                "Body corporate"
              </td>{" "}
              <td style={{ padding: 15 }}>
                "Body corporate" means any company and includes a firm, sole
                proprietorship or other association of individuals engaged in
                commercial or professional activities.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>"Child"</td>{" "}
              <td style={{ padding: 15 }}>
                "Child" means a data provider below the age of eighteen years
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>
                "Cyber incidents"
              </td>{" "}
              <td style={{ padding: 15 }}>
                "Cyber incidents" means any real or suspected adverse event in
                relation to cyber security that violates an explicitly or
                implicitly applicable security policy resulting in unauthorised
                access, denial of service or disruption, unauthorised use of a
                computer resource for processing or storage of information or
                changes to data, information without authorisation.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>
                "Cyber security"
              </td>{" "}
              <td style={{ padding: 15 }}>
                "cyber security" means protecting information, equipment,
                devices, computer, computer resource, communication device and
                information stored therein from unauthorised access, use,
                disclosure, disruption, modification or destruction.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>"Data"</td>{" "}
              <td style={{ padding: 15 }}>
                "Data" means a representation of information, knowledge, facts,
                concepts or instructions which are being prepared or have been
                prepared in a formalised manner, and is intended to be
                processed, is being processed or has been processed in a
                computer system or computer network, and may be in any form
                (including computer printouts magnetic or optical storage media,
                punched cards, punched tapes) or stored internally in the memory
                of the computer.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>
                "Data provider"
              </td>{" "}
              <td style={{ padding: 15 }}>
                "Data provider" means the natural person to whom the personal
                data relates to.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>
                "Data protection &amp; Security"
              </td>{" "}
              <td style={{ padding: 15 }}>
                Anyone collecting personal &amp; customer information must
                fairly &amp; lawfully process it only for limited, specifically
                stated purposes and use the information accurately and in a way
                that is adequate, relevant and not excessive, and retain the
                information/records no longer than absolutely necessary, process
                the information in accordance with law and keep the information
                secure and never transfer the information outside the country
                without adequate protection.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>"Password"</td>{" "}
              <td style={{ padding: 15 }}>
                "Password" means a secret word or phrase or code or passphrase
                or secret key, or encryption or decryption keys that one uses to
                gain admittance or access to information.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>
                "Personal information"
              </td>{" "}
              <td style={{ padding: 15 }}>
                "Personal information" means any information that relates to a
                natural person (individual), which, either directly or
                indirectly, in combination with other information available or
                likely to be available with a body corporate, is capable of
                identifying such person, i.e., Name, Address, mobile Number,
                email id, Date of birth etc.,.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>
                "Data processor"
              </td>{" "}
              <td style={{ padding: 15 }}>
                "Data processor" means any person, including the State, a
                company, any juristic entity or any individual who processes
                personal data on behalf of a data fiduciary, but does not
                include an employee of the data fiduciary.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>"Processing"</td>{" "}
              <td style={{ padding: 15 }}>
                "Processing" in relation to personal data, means an operation or
                set of operations performed on personal data, and may include
                operations such as collection, recording, organisation,
                structuring, storage, adaptation, alteration, retrieval, use,
                alignment or combination, indexing, disclosure by transmission,
                dissemination or otherwise making available, restriction,
                erasure or destruction.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>
                "reasonable security practices and procedures"
              </td>{" "}
              <td style={{ padding: 15 }}>
                "reasonable security practices and procedures" means security
                practices and procedures designed to protect such information
                from unauthorised access, damage, use, modification, disclosure
                or impairment, as may be specified in an agreement between the
                parties or as may be specified in any law for the time being in
                force and in the absence of such agreement or any law, such
                reasonable security practices and procedures, as may be
                prescribed by the Central Government in consultation with such
                professional bodies or associations as it may deem fit.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>
                "Sensitive personal data or information"
              </td>{" "}
              <td style={{ padding: 15 }}>
                Sensitive personal data or information of a person means such
                personal information which consists of information relating to;
                -
                <ol style={{ listStyleType: "lower-latin" }}>
                  <li>password;</li>
                  <li>
                    financial information such as Bank account or credit card or
                    debit card or other payment instrument details ;
                  </li>
                  <li>physical, physiological and mental health condition;</li>
                  <li>sexual orientation;</li>
                  <li>medical records and history;</li>
                  <li>Biometric information;</li>
                  <li>
                    any detail relating to the above clauses as provided to body
                    corporate for
                  </li>
                  <li>providing service; and</li>
                  <li>
                    any of the information received under above clauses by body
                    corporate for processing, stored or processed under lawful
                    contract or otherwise;
                  </li>
                </ol>
                Provided that, any information that is freely available or
                accessible in public domain or furnished under the Right to
                Information Act, 2005 or any other law for the time being in
                force shall not be regarded as sensitive personal data or
                information.
              </td>
            </tr>
            <tr>
              <td style={{ padding: 15, fontWeight: "bold" }}>"Third Party"</td>{" "}
              <td style={{ padding: 15 }}>
                All external parties, i.e. contractors, interns, trainees,
                vendors, users etc., who have accessed to Pager-Ai information
                assets or information systems.
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </Container>
  );
};

export default PrivacyPolicy;
