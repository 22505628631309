import {
  Container,
  Card,
  Button,
  Box,
  Grid,
  FormControl,
  OutlinedInput,
  Typography,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ErrorIcon from "@mui/icons-material/Error";
import HomeIcon from "@mui/icons-material/Home";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

const Cab = () => {
  const [active, setActive] = useState(true);
  return (
    <div>
      <Container>
        <Box
          my={5}
          display="flex"
          width="100%"
          mx="auto"
          justifyContent="center"
          alignItems="center"
        >
          <Card sx={{ width: 375, padding: "3rem 2rem" }}>
            <Grid
              container
              spacing={5}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item sx={6}>
                <Button
                  variant={active ? "contained" : ""}
                  sx={{ borderRadius: 10 }}
                  size="large"
                  onClick={() => setActive(true)}
                >
                  Rides
                </Button>
              </Grid>
              <Grid item sx={6}>
                <Button
                  variant={!active ? "contained" : ""}
                  sx={{ borderRadius: 10 }}
                  size="large"
                  onClick={() => setActive(false)}
                >
                  Rentals
                </Button>
              </Grid>
            </Grid>
            <Box
              mt={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <FormControl sx={{ width: "65ch" }}>
                <Box display="flex" alignItems="center">
                  <Box>
                    <LocationOnIcon style={{ marginRight: "0.35rem" }} />
                  </Box>
                  <OutlinedInput
                    size="small"
                    placeholder="Enter pickup location"
                    fullWidth
                    required
                    sx={{ borderRadius: 4, margin: "0.5rem 0rem" }}
                  />
                  <Box>
                    <ErrorIcon style={{ marginLeft: "0.35rem" }} />
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box>
                    <LocationOnIcon style={{ marginRight: "0.35rem" }} />
                  </Box>
                  <OutlinedInput
                    size="small"
                    placeholder="Enter drop location"
                    fullWidth
                    required
                    sx={{ borderRadius: 4, margin: "0.5rem 0rem" }}
                  />
                  <Box>
                    <ErrorIcon style={{ marginLeft: "0.35rem" }} />
                  </Box>
                </Box>
                {!active ? (
                  <Box m={1}>
                    <Typography
                      style={{ textAlign: "center", marginBottom: "1rem" }}
                    >
                      Check Serviceable Area
                    </Typography>
                    <Box height="300px">
                      <MapContainer
                        center={[12.9716, 77.5946]}
                        zoom={15}
                        scrollWheelZoom={false}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[12.9716, 77.5946]}>
                          <Popup>Mark</Popup>
                        </Marker>
                      </MapContainer>
                    </Box>
                    <Box mt={2}>
                      <Button variant="contained" color="primary" fullWidth>
                        Confirm Route
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    //   my={5}
                    display="flex"
                    width="100%"
                    mx="auto"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      container
                      spacing={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <Typography
                          style={{ textAlign: "center", marginBottom: "1rem" }}
                        >
                          Add all stops now
                        </Typography>
                        <Divider />
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <HomeIcon style={{ marginRight: "0.35rem" }} />
                          <Typography style={{ textAlign: "center" }}>
                            Add Home
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <BusinessCenterIcon
                            style={{ marginRight: "0.35rem" }}
                          />
                          <Typography
                            variant=""
                            style={{ textAlign: "center" }}
                          >
                            Add Work
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" ml={4}>
                          <FavoriteIcon style={{ marginRight: "0.35rem" }} />
                          <Typography>Favourite Places</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" ml={4}>
                          <FlightTakeoffIcon
                            style={{ marginRight: "0.35rem" }}
                          />
                          <Typography style={{ textAlign: "center" }}>
                            Indira Gandhi International Airport
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </FormControl>
            </Box>
          </Card>
        </Box>
      </Container>
    </div>
  );
};

export default Cab;
