import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FilledInput,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import logo from "../../assets/images/logo2.png";
import countries from "country-list-with-dial-code-and-flag";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const countryData = countries.getAll();

  const [selectedCountry, setSelectedCountry] = React.useState("");
  console.log(selectedCountry);

  const handleCountryChange = (event, newValue) => {
    setSelectedCountry(newValue);
  };

  const countryOptions = countryData.map((country) => ({
    label: `${country.flag}  ${country.dial_code}`,
    value: country.name,
  }));
  return (
    <div
      style={{
        width: "100vw",
        // height: "80vh",
        marginTop: "2rem",

        marginBottom: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box width={400}>
        <Paper sx={{ padding: "1rem", borderRadius: "10px" }}>
          <Box sx={{ display: "flex", justifyContent: "center", my: "2rem" }}>
            <img src={logo} alt="logo" width={200} />
          </Box>
          <Typography
            sx={{ fontWeight: "bold", mb: "1rem" }}
            variant="h6"
            textAlign={"center"}
          >
            Register
          </Typography>

          <TextField
            sx={{ mb: "1rem" }}
            id="filled-basic"
            label="First Name"
            fullWidth
            variant="filled"
          />
          <TextField
            sx={{ mb: "1rem" }}
            id="filled-basic"
            label="Last Name"
            fullWidth
            variant="filled"
          />
          <Box display={"flex"}>
            <Autocomplete
              options={countryOptions}
              sx={{ width: "280px", marginRight: "0.2rem" }}
              getOptionLabel={(option) => option.label || ""}
              renderInput={(params) => (
                <TextField fullWidth {...params} label="" variant="filled" />
              )}
              value={selectedCountry}
              onChange={handleCountryChange}
              filterOptions={(options, { inputValue }) => {
                const inputValueLowerCase = inputValue.toLowerCase();
                return options.filter(
                  (option) =>
                    option.label.toLowerCase().includes(inputValueLowerCase) ||
                    option.value.toLowerCase().includes(inputValueLowerCase)
                );
              }}
            />

            <TextField
              sx={{ mb: "1rem" }}
              id="filled-basic"
              label=""
              placeholder="XXXXXXXXXX"
              fullWidth
              variant="filled"
            />
          </Box>
          <TextField
            sx={{ mb: "1rem" }}
            id="filled-basic"
            type="email"
            label="Email Address"
            fullWidth
            variant="filled"
          />
          <FormControl fullWidth sx={{}} variant="filled">
            <InputLabel htmlFor="filled-adornment-password">
              Password
            </InputLabel>
            <FilledInput
              id="filled-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          <Box m={2}>
            <Typography
              sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
              variant="body1"
            >
              Password must contain
            </Typography>
            <Typography variant="body2">
              A lowercase letter <br />
              A capital (uppercase letter)
              <br />
              A number
              <br />
              Minimum 8 characters
              <br />
              Maximum 16 characters
              <br />
            </Typography>
          </Box>
          <Box display="flex">
            <FormControlLabel
              control={
                <Checkbox
                  // checked={isAgree}
                  // onChange={(e) => setIsAgree(e.target.checked)}
                  size="small"
                  // sx={{ color: agreeError ? "red" : "" }}
                />
              }
            />
            <Typography
              sx={{
                color: "black",
                fontSize: "0.9rem",
                marginTop: "0.4rem",
                ml: "-0.5rem",
              }}
            >
              I agree to the{" "}
              <span style={{ color: "black", fontWeight: "bold" }}>
                terms and conditions.
              </span>
            </Typography>
          </Box>
          <Box my={3}>
            <Button variant="contained" sx={{ py: "0.8rem" }} fullWidth>
              Submit
            </Button>
          </Box>

          <Box onClick={() => navigate("/sign-in")} sx={{ cursor: "pointer" }}>
            <Typography textAlign={"center"}>
              Already have account?{" "}
              <span style={{ fontWeight: "bold", color: "#00539f" }}>
                Log in{" "}
              </span>
            </Typography>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default SignUp;
