import {
  Container,
  Card,
  Button,
  Box,
  Grid,
  FormControl,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ErrorIcon from "@mui/icons-material/Error";
import LocationCityIcon from "@mui/icons-material/LocationCity";

const Courier = () => {
  const [active, setActive] = useState(true);
  return (
    <div>
      <Container>
        <Box
          my={5}
          display="flex"
          width="100%"
          mx="auto"
          justifyContent="center"
          alignItems="center"
        >
          <Card sx={{ width: 775, padding: "3rem 2rem" }}>
            <Grid
              container
              spacing={5}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item sx={6}>
                <Button
                  variant={active ? "contained" : ""}
                  sx={{ borderRadius: 10 }}
                  size="large"
                  onClick={() => setActive(true)}
                >
                  Domestic
                </Button>
              </Grid>
              <Grid item sx={6}>
                <Button
                  variant={!active ? "contained" : ""}
                  sx={{ borderRadius: 10 }}
                  size="large"
                  onClick={() => setActive(false)}
                >
                  International
                </Button>
              </Grid>
            </Grid>
            <Box
              mt={5}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <FormControl sx={{ width: "65ch" }}>
                {!active ? (
                  <>
                    <Box display="flex">
                      <Typography sx={{ flexGrow: 1 }}>
                        Destination Country*
                      </Typography>
                    </Box>
                    <OutlinedInput
                      placeholder=" Destination Country"
                      fullWidth
                      required
                      sx={{ borderRadius: 10, margin: "1rem 0rem" }}
                      startAdornment={
                        <LocationCityIcon style={{ marginRight: "0.35rem" }} />
                      }
                    />
                  </>
                ) : (
                  ""
                )}

                <Box display="flex">
                  <Typography sx={{ flexGrow: 1 }}>
                    Origin / Shipper Pincode*
                  </Typography>
                  <ErrorIcon />
                </Box>
                <OutlinedInput
                  placeholder="Enter origin Pincode"
                  fullWidth
                  required
                  sx={{ borderRadius: 10, margin: "1rem 0rem" }}
                  startAdornment={
                    <LocationOnIcon style={{ marginRight: "0.35rem" }} />
                  }
                />
                <Box display="flex">
                  <Typography sx={{ flexGrow: 1 }}>
                    Destination / Consignee Pincode*
                  </Typography>
                  <ErrorIcon />
                </Box>
                <OutlinedInput
                  placeholder="Enter destination Pincode"
                  fullWidth
                  sx={{ borderRadius: 10, margin: "1rem 0rem" }}
                  startAdornment={
                    <LocationOnIcon style={{ marginRight: "0.35rem" }} />
                  }
                />
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ borderRadius: 10, margin: "1rem 0rem" }}
                  size="large"
                >
                  Get Started
                </Button>
              </FormControl>
            </Box>
          </Card>
        </Box>
      </Container>
    </div>
  );
};

export default Courier;
