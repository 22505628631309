import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React from "react";
import img from "../../assets/images/img.png";
import CheckIcon from "@mui/icons-material/Check";
import countries from "country-list-with-dial-code-and-flag";

const Listing = () => {
  const countryData = countries.getAll();

  // State to store the selected country
  const [selectedCountry, setSelectedCountry] = React.useState("");

  // Function to handle select change
  const handleCountryChange = (event, newValue) => {
    setSelectedCountry(newValue);
  };

  const countryOptions = countryData.map((country) => ({
    label: `${country.dial_code}`,
    value: country.name,
  }));

  return (
    <div>
      <Container>
        <Box padding="2rem 0rem">
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              style={{ marginTop: "2rem" }}
            >
              <Typography variant="h4" fontWeight="bold" guttonButtom>
                List Your Business{" "}
                <span style={{ color: "blue" }}>for FREE</span>
              </Typography>
              <Typography variant="h6">
                with India’s No. 1 Local Search Engine
              </Typography>
              <Box m="2rem 0rem" display="flex">
                <FormControl sx={{ mr: 1, width: 100 }} size="small">
                  <Autocomplete
                    options={countryOptions}
                    // sx={{ width: "280px", marginRight: "0.1rem" }}
                    getOptionLabel={(option) => option.label || ""}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        label=""
                        sx={{ width: 60 }}
                      />
                    )}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    filterOptions={(options, { inputValue }) => {
                      const inputValueLowerCase = inputValue.toLowerCase();
                      return options.filter(
                        (option) =>
                          option?.label
                            .toLowerCase()
                            .includes(inputValueLowerCase) ||
                          option?.value
                            .toLowerCase()
                            .includes(inputValueLowerCase)
                      );
                    }}
                    clearIcon={null}
                  />
                </FormControl>
                <FormControl variant="outlined">
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-weight"
                    placeholder="Enter Mobile Number"
                    endAdornment={
                      <InputAdornment position="end">
                        <Button
                          color="primary"
                          variant="contained"
                          endIcon={<SendIcon />}
                        >
                          <Box>Start Now</Box>
                        </Button>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>

              <Box display="flex" m="1rem 0rem">
                <CheckIcon fontSize="large" />
                <Typography variant="h6" style={{ marginLeft: "0.5rem" }}>
                  Get Discovered and Create Your Online Business
                </Typography>
              </Box>
              <Box display="flex" m="1rem 0rem">
                <CheckIcon fontSize="large" />
                <Typography variant="h6" style={{ marginLeft: "0.5rem" }}>
                  Respond to Customer Reviews and Questions
                </Typography>
              </Box>
              <Box display="flex" m="1rem 0rem">
                <CheckIcon fontSize="large" />
                <Typography variant="h6" style={{ marginLeft: "0.5rem" }}>
                  Showcase Your Product and Service Offerings
                </Typography>
              </Box>
              <Typography sx={{ marginTop: 5 }}>
                By continuing, you agree to our Terms of Use , Privacy &
                Infringement Policy
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <img src={img} alt="side-info" width={"100%"} height={550} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default Listing;
