import { useState } from "react";
import {
  Typography,
  Container,
  Card,
  CardContent,
  Box,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const list_items = [
  "",
  "Individual/Family Insurance",
  "Top up Insurance",
  "Health Infinity Plan",
  "Critical Illness Insurance",
];
const Insurance = () => {
  const [active, setActive] = useState("car");
  return (
    <Box
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      my={2}
    >
      <Container>
        <Typography
          variant="h5"
          style={{ fontWeight: 400 }}
          gutterBottom
          align="center"
        >
          Get Your Insurance
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-around">
          <Card
            style={{
              borderRadius: 15,
              boxShadow: "0 0 4px 1px #b8b8b8",
            }}
            onClick={() => setActive("car")}
          >
            <CardContent
              style={{
                padding: "10px 10px",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DirectionsCarIcon
                fontSize="large"
                style={{ color: active === "car" ? "#EF9E35" : "#000" }}
              />
            </CardContent>
          </Card>
          <Card
            style={{
              borderRadius: 15,
              boxShadow: "0 0 4px 1px #b8b8b8",
            }}
            onClick={() => setActive("bike")}
          >
            <CardContent
              style={{
                padding: "10px 10px",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TwoWheelerIcon
                fontSize="large"
                style={{ color: active === "bike" ? "#EF9E35" : "#000" }}
              />
            </CardContent>
          </Card>
          <Card
            style={{
              borderRadius: 15,
              boxShadow: "0 0 4px 1px #b8b8b8",
            }}
            onClick={() => setActive("health")}
          >
            <CardContent
              style={{
                padding: "10px 10px",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LocalHospitalIcon
                fontSize="large"
                style={{ color: active === "health" ? "#EF9E35" : "#000" }}
              />
            </CardContent>
          </Card>
        </Box>
        <Card
          style={{
            padding: "50px 20px",
            borderRadius: 15,
            boxShadow: "0 0 4px 1px #b8b8b8",
            marginTop: "-25px",
          }}
        >
          <CardContent>
            <form>
              {/* <Box
                width="100%"
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
              > */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} xl={3}>
                  {active !== "health" ? (
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      id="standard-basic"
                      required
                      placeholder="E.G. MH12FA1606"
                      label="Enter registration number"
                      variant="standard"
                    />
                  ) : (
                    <TextField
                      id="standard-select-currency-native"
                      select
                      required
                      fullWidth
                      label="Select product"
                      defaultValue="EUR"
                      SelectProps={{
                        native: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="standard"
                    >
                      {list_items.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </TextField>
                  )}
                </Grid>

                <Grid item xs={12} sm={12} md={3} xl={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    id="standard-basic"
                    required
                    placeholder="First name  Last name"
                    label=" Enter name"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} xl={3}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    id="standard-basic"
                    required
                    placeholder="Enter mobile no."
                    label=" Mobile number"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} xl={3}>
                  <Button
                    type="submit"
                    style={{
                      textTransform: "unset",
                      boxShadow: "none",
                      padding: "1rem 2rem",
                      borderRadius: 30,
                      backgroundColor: "#EF9E35",
                    }}
                    variant="contained"
                  >
                    Get Quote
                  </Button>
                </Grid>
              </Grid>

              {/* </Box> */}
            </form>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default Insurance;
