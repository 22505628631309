import {
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import doctorImg from "../../assets/images/doctorimg.jpg";
import one from "../../assets/images/ayurvedic.png";
import two from "../../assets/images/pain-in-joints.png";
import three from "../../assets/images/dental-care.png";
import four from "../../assets/images/chest-pain.png";
import five from "../../assets/images/delivery.png";
import six from "../../assets/images/doctor-consultation.png";
import seven from "../../assets/images/dental-care.png";
import eight from "../../assets/images/diabetes.png";
import nine from "../../assets/images/diabetes.png";
import ten from "../../assets/images/gp.png";
import eye from "../../assets/images/eye.png";
import gaster from "../../assets/images/gastroenterologist.png";
import general from "../../assets/images/homeopathy.png";
import neonatolagists from "../../assets/images/neurologist.png";
import onCall from "../../assets/images/doctor-on-call.png";
import children from "../../assets/images/children.png";

import SearchIcon from "@mui/icons-material/Search";
import Fuse from "fuse.js";

const doctorCategory = [
  {
    name: "Ayurvedic Doctors",
    icon: one,
  },
  {
    name: "Bone & Joints Doctors",
    icon: two,
  },
  {
    name: "Cardiolofist ",
    icon: three,
  },
  {
    name: "Chest Specialists",
    icon: four,
  },
  {
    name: "Child Specialists Doctors",
    icon: five,
  },
  {
    name: "Cosmetic Doctorsurgeons",
    icon: six,
  },
  {
    name: "Dentists ",
    icon: seven,
  },
  {
    name: "Dermatologists",
    icon: eight,
  },
  {
    name: "Diabetologists",
    icon: nine,
  },
  {
    name: "Dietitions", // not found
    icon: ten,
  },
  {
    name: "ENT Specialists",
    icon: ten,
  },

  {
    name: "Eye Specialists",
    icon: eye,
  },
  {
    name: "Gastroenterologists",
    icon: gaster,
  },
  {
    name: "General Physicians",
    icon: general,
  },
  {
    name: "Gynaecologists & Obstetricians",
    icon: neonatolagists,
  },
  {
    name: "Homeopathic Doctors",
    icon: children,
  },
  {
    name: "Neonatolagists", //not found
    icon: three,
  },
  {
    name: "Neurologists",
    icon: neonatolagists,
  },
  {
    name: "Neurosorgeons",
    icon: five,
  },
  {
    name: "Oncologists",
    icon: eight,
  },
  {
    name: "On Call Doctors",
    icon: onCall,
  },
  {
    name: "Ophthalmalogists",
    icon: six,
  },
  {
    name: "Orthopaedic Doctors",
    icon: two,
  },
  {
    name: "Paediatricians",
    icon: children,
  },
  {
    name: "Physiotherapists",
    icon: ten,
  },
  {
    name: "Physiotherapists For Home Visit",
    icon: three,
  },
  {
    name: "Piles Dectors",
    icon: neonatolagists,
  },
  {
    name: "Psychiatrists",
    icon: seven,
  },
  {
    name: "Psychologists",
    icon: four,
  },
  {
    name: "Sexologists",
    icon: five,
  },
  {
    name: "Skin & Hair Doctors",
    icon: neonatolagists,
  },
  {
    name: "Skin Doctors",
    icon: onCall,
  },
  {
    name: "Speech Therapists",
    icon: general,
  },
  {
    name: "Thyroid Doctors",
    icon: children,
  },
  {
    name: "Trichologists",
    icon: two,
  },
  {
    name: "Unani Doctors",
    icon: ten,
  },
  {
    name: "Urologist",
    icon: general,
  },
  {
    name: "Veterinary ",
    icon: nine,
  },
];
const Doctor = () => {
  const [query, setQuery] = useState("");

  const handleSearchClick = (query) => {
    const option = {
      isCaseSensitive: true,
      includeScore: false,
      shouldSort: true,
      includeMatches: false,
      findAllMatches: false,
      minMatchCharLength: 1,
      location: 0,
      threshold: 0.6,
      distance: 100,
      useExtendedSearch: true,
      ignoreLocation: false,
      ignoreFieldNorm: false,
      fieldNormWeight: 1,
      keys: ["name"],
    };

    const fuse = new Fuse(doctorCategory, option);
    const result = fuse.search(query);

    const searchedResult = result.map((result) => result.item);

    setQdata(searchedResult);
  };
  useEffect(() => {
    if (query !== "") {
      setTimeout(() => handleSearchClick(query), 100);
    }
  }, [query]);
  const [qdata, setQdata] = useState([]);
  return (
    <div>
      <Box
        sx={{
          backgroundImage: `url(${doctorImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          objectFit: "contain",
          height: 300, // Set the height of the box
          width: "100%",
        }}
      />
      <Container>
        <Box sx={{ mt: "2rem" }}>
          <TextField
            variant="outlined"
            label=""
            size="small"
            name="company"
            placeholder="Search all category"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <SearchIcon sx={{ color: "#000", marginRight: "8px" }} />
              ),
              sx: {
                // maxWidth: "350px",
                marginBottom: "2rem",
              },
            }}
          />
        </Box>
        <Grid
          container
          // display={"flex"}
          // justifyContent={"center"}
          sx={{ mb: "2rem" }}
          columnSpacing={5}
          rowSpacing={2}
        >
          {query === ""
            ? doctorCategory.map((item, i) => (
                <Grid item key={i} sm={6} xs={12} md={4} xl={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      background: i % 2 === 0 ? "#fbfbfb" : "",
                      padding: "1rem",
                      cursor: "pointer",
                      overflow: "hidden",
                      position: "relative",
                      "&:hover": {
                        "& > div > :last-child": {
                          color: "blue",
                          transition: "transform 0.3s ease-in-out",
                          transform: "translateX(5%)",
                        },
                      },
                    }}
                    component={"div"}
                  >
                    <Box display={"flex"}>
                      <img
                        width={30}
                        height={30}
                        src={item.icon}
                        alt={item.name}
                      />
                      <Typography ml={2}>{item.name}</Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))
            : qdata.map((item, i) => (
                <Grid item key={i} sm={6} xs={12} md={4} xl={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      background: i % 2 === 0 ? "#fbfbfb" : "",
                      padding: "1rem",
                      cursor: "pointer",
                      overflow: "hidden",
                      position: "relative",
                      "&:hover": {
                        "& > div > :last-child": {
                          color: "blue",
                          transition: "transform 0.3s ease-in-out",
                          transform: "translateX(5%)",
                        },
                      },
                    }}
                    component={"div"}
                  >
                    <Box display={"flex"}>
                      <img
                        width={30}
                        height={30}
                        src={item.icon}
                        alt={item.name}
                      />
                      <Typography ml={2}>{item.name}</Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Doctor;
