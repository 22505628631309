import React from "react";

import {
  Typography,
  Container,
  Card,
  CardContent,
  Box,
  Button,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Grid,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Pathology = () => {
  return (
    <Box
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      my={2}
    >
      <Container>
        <Typography
          variant="h5"
          style={{ fontWeight: 400 }}
          gutterBottom
          align="center"
        >
          Lab Test at Home
        </Typography>
        <Grid container spacing={3}>
          {path_data.map((item, i) => (
            <Grid item lg={4} md={6} sm={12} xs={12} key={i}>
              <Card
                style={{
                  padding: 15,
                  borderRadius: 10,
                  boxShadow: "0px 16px 28px 0px #24242414",
                }}
              >
                <CardContent>
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "uppercase", fontWeight: 900 }}
                    gutterBottom
                  >
                    {item?.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 400 }}
                    gutterBottom
                  >
                    {item?.num}Parameter(s) Covered
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 600 }}
                    gutterBottom
                  >
                    ₹ {item?.price}
                  </Typography>
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent={"space-between"}
                  >
                    <Box display="flex">
                      <CheckCircleIcon
                        fontSize="small"
                        style={{ marginRight: "1rem", color: "#EE9F39" }}
                      />
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 600, color: "#EE9F39" }}
                        gutterBottom
                      >
                        Home Collection
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <CheckCircleIcon
                        fontSize="small"
                        style={{ marginRight: "1rem", color: "#EE9F39" }}
                      />
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 600, color: "#EE9F39" }}
                        gutterBottom
                      >
                        Lab Visit
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent={"space-between"}
                    alignItems="center"
                    my={1}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        boxShadow: "none",
                        fontSize: "0.8rem",
                        textTransform: "unset",
                        borderRadius: 12,
                        padding: "7px 10px",
                      }}
                    >
                      Add to Cart
                    </Button>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Compare"
                      />
                    </FormGroup>
                    <Button
                      variant="text"
                      color="primary"
                      style={{
                        textTransform: "unset",
                        fontWeight: 800,
                        fontSize: "0.8rem",
                      }}
                    >
                      Know More
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Pathology;

const path_data = [
  {
    title: "COMPLETE BLOOD COUNT; CBC",
    num: 20,
    price: 350,
  },
  {
    title: "HbA1c; GLYCOSYLATED HEMOGLOBIN",
    num: 2,
    price: 440,
  },
  {
    title: "URINE EXAMINATION, ROUTINE; URINE, R/E",
    num: 16,
    price: 120,
  },
  {
    title: "GLUCOSE, FASTING (F)",
    num: 1,
    price: 80,
  },
  {
    title: "THYROID PROFILE, TOTAL",
    num: 3,
    price: 550,
  },
  {
    title: "SMART REPORT",
    num: 1,
    price: 200,
  },
];
