import { Box, Container, Typography } from "@mui/material";
import React from "react";

const About = () => {
  return (
    <Container>
      <Box sx={{ margin: "7rem 0rem " }}>
        <Typography variant="h4" sx={{ marginBottom: 5 }}>
          Welcome to Pager-Ai – Simplifying Your Life, One Click Away!
        </Typography>
        <Typography sx={{ marginBottom: 5 }}>
          At Pager-Ai , we understand the challenges of managing various tasks
          in today's fast-paced world. That's why we've curated a comprehensive
          suite of services accessible through your favorite social media
          platform. With a simple click, you unlock a world of convenience and
          efficiency right at your fingertips.
        </Typography>
        <Typography variant="h5" sx={{ marginBottom: 3 }}>
          Our Range of Services:
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Book a Courier:
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Say goodbye to long queues and complex procedures. Our user-friendly
          platform enables you to schedule courier services effortlessly. From
          important documents to packages, we ensure swift and reliable
          deliveries.
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Book Pathology Test Online:
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Prioritizing your health has never been easier. Schedule your
          pathology tests online, allowing you to monitor and manage your health
          from the comfort of your home.
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Book Doctor:
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Instantly connect with experienced medical professionals. Book
          appointments, seek consultations, and access healthcare services
          conveniently, ensuring your well-being is never compromised.
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Book a Cab:
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Need a ride? We've got you covered. Book a cab with a simple tap,
          ensuring safe and comfortable transportation to your destination.
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Buy Insurance:
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Protect what matters most. Explore and purchase a range of insurance
          plans tailored to your needs, providing peace of mind for you and your
          loved ones.
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Sell Your Electronics:
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Ready to upgrade your gadgets? Sell your pre-loved electronics
          hassle-free through our platform, ensuring a seamless selling
          experience.
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Repair & Services:
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          From device repairs to maintenance services, our platform connects you
          with trusted professionals to ensure your electronic devices are in
          top-notch condition.
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          How It Works:
        </Typography>
        <Typography sx={{ marginBottom: 2 }}>
          Through our user-friendly interface on social media, each service is
          seamlessly integrated, offering you a streamlined experience. With a
          click, gain access to these essential services, all designed to
          simplify your day-to-day activities.
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Our Commitment:
        </Typography>
        <Typography sx={{ marginBottom: 5 }}>
          We're dedicated to providing you with not just services, but a
          solution-driven experience. Your satisfaction and convenience remain
          our primary focus, and we continuously strive to enhance our offerings
          to meet your evolving needs.
        </Typography>
        <Typography variant="h6" sx={{ marginBottom: 1 }}>
          Join us on social media today and discover a world of convenience at
          your fingertips.
        </Typography>
      </Box>
    </Container>
  );
};

export default About;
