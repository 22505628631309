import "./styles/App.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/home";
import Doctor from "./pages/doctor";
import RepairServices from "./pages/repairServices";
import ContactUs from "./components/Contact.js";
import About from "./pages/about/index.js";
import NotFound from "./pages/notFound/404.js";
import Courier from "./pages/courier/Courier.js";
import SellElectronic from "./pages/sellElectronic/sellElectronic.js";
import SignUp from "./pages/signUp/signUp.js";
import SignIn from "./pages/signIn/signIn.js";
import ForgotPassword from "./pages/forgotPassword/forgotPassword.js";

import Cab from "./pages/cab/Cab.js";
import Listing from "./pages/listing/Listing.js";
import Pathology from "./pages/pathology/pathology.js";
import Insurance from "./pages/insurance/insurance.js";
import PrivacyPolicy from "./pages/privacypolicy/privacypolicy.js";
import Infringment from "./pages/infrinigement/infringement.js";
// import "react-languages-select/css/react-languages-select.css";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="book-doctor" element={<Doctor />} />
          <Route path="repair-services" element={<RepairServices />} />
          <Route path="book-courier" element={<Courier />} />
          <Route path="book-cab" element={<Cab />} />
          <Route path="sell-electronic" element={<SellElectronic />} />
          <Route path="about-us" element={<About />} />
          <Route path="/list-business" element={<Listing />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="sign-in" element={<SignIn />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="/pathology" element={<Pathology />} />
          <Route path="/insurance" element={<Insurance />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/infringement" element={<Infringment />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </>
    )
  );
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
