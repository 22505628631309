import { Container, Typography, Divider } from "@mui/material";
const Infringment = () => {
  return (
    <Container style={{ marginTop: "2rem", marginBottom: "2rem" }}>
      <Typography variant="h5" gutterBottom>
        Infringement policy
      </Typography>
      <Divider />

      <p>
        Pager-Ai portal contains the trademark, logos, trade name, service marks
        and other marks (collectively&nbsp;<b>"Marks"</b>) which are the
        intellectual property of Pager-Ai or their vendors or respective third
        parties. You understand that Pager-Ai uses the Marks of vendors and
        respective third parties procured from the owner and/or distribution
        channel. In doing so, Pager-Ai has no intention, whatsoever, to acquire
        rights of use or license to these Marks.
      </p>
      <p>
        This Infringement policy helps in creating a safer marketplace by
        prohibiting the use of Pager-Ai portal for infringing the intellectual
        property rights of third parties or of the rightful owner. Pager-Ai
        policy requires sellers and buyers or users to comply with all
        governmental laws and regulations including but not limited to the
        Infringement policy as amended from time to time.
      </p>
      <p>
        If you see something on the Just Dial portal, which you believe is
        violating the intellectual property rights, please send an infringement
        initimation to Just Dial at
        <a href="mailto:legal@pager-ai.com"> legal@pager-ai.com</a>
      </p>
      <p>
        Pager-Ai will be unable to process requests which do not specify exact
        product IDs or URLs and the details which are specified below. Please do
        not provide links to browse pages or links of search queries as these
        pages are dynamic and their contents change with time.
      </p>
      <p>
        <b> How to report a listing </b>
      </p>
      <p>
        If you have a good faith belief that your IP right has been infringed by
        any of our sellers or anyone else on Pager-Ai ’s portal , you may follow
        the below process and /or seek Legal advice.
      </p>
      <p>
        We require that the Intellectual Property right owner or authorized
        representative provide the following details and email to the
        aforementioned email id.
      </p>
      <p>
        <b> The email should contain the below information. </b>
      </p>
      <ol type="I" className="inpolcy_ol">
        <li>
          Identification or description of the Intellectual Property Rights that
          has been infringed.
        </li>
        <li>
          Unambiguous identification or description of where the material that
          you claim is infringing is located on Pager-Ai portal with adequate
          particulars. Product ID / website links of infringing products.{"{"}"
          "{"}"}
        </li>
        <li> Your address, telephone number, and email address. </li>
        <li>
          A statement by you, that the information in your notice is accurate
          and that you are the copyright or intellectual property owner or
          authorized to act on the copyright or intellectual property owner's
          behalf.
        </li>
        <li> Brand/Trade Name (in case of Trademark infringement) </li>
        <li>
          Details of the intellectual property being infringed (Provide
          copyrighted images or trademark certificates as attachments){"{"}" "
          {"}"}
        </li>
        <li>
          Details/documents of legal proceedings initiated against the entities
          (infringing the Intellectual Property rights of the owner)
        </li>
      </ol>
      <p />
      <p>
        <b>Grievance Redressal policy.:</b>
      </p>
      <p>
        Pager-Ai shall take all necessary steps and/or remove the infringed
        product/details from its portal within 15 (Fifteen) working days subject
        to verification of the details as given above and the nature of
        complaint.
      </p>
      <p>
        If you have any questions or concerns about this Agreement please
        contact us for more information.
      </p>
      <h4 className="flhd nw_tmhd">
        COUNTERFEIT &amp; PRIVACY PREVENTION POLICY
      </h4>
      <p>
        <b className="main_point">A. Introduction:</b>
      </p>
      <p>
        1. We have adopted an Anti-Counterfeit &amp; Piracy Policy to eliminate
        the impact of counterfeit and pirated products on our platform and our
        customers. We prohibit the sale or promotion for sale of counterfeit and
        pirated goods/service on our platform and have strengthened our
        framework to effectively combat the proliferation and sale of
        counterfeit and pirated products on our digital platform. This policy
        describes the ways Pager-Ai Limited and its affiliates (collectively
        “Pager-Ai , we, our, us”) protect the rights of the owner, manufacturer,
        inventor, musician, author from the sale of counterfeit and pirated
        products on its website www.justdial.com mobile application, and m-site
        (hereinafter referred to as the “Platform”). This is a zero tolerance
        Policy against knowingly and intentionally trafficking, listings,
        displaying, uploading and selling counterfeit and pirated products on
        our platform.
      </p>
      <p>
        2. This Policy is an electronic agreement formed under the Information
        Technology Act, 2000 and the rules and regulations made there under (as
        amended till date) including Information Technology (Intermediary
        Guidelines and Digital Media Ethics Code) Rules, 2021. It does not
        require any physical or digital signatures to make the terms of this
        policy binding. This policy is a legally binding document. By visiting
        our Platform or registered with us, you expressly agree to be bound by
        the terms of this Policy and the other applicable Terms and/or policy.
        If you do not agree, please do not access or use our Platform/ Service.
      </p>
      <p>
        <b className="main_point">B. Purpose:</b>
      </p>
      <p>1. To help mitigate the risk of counterfeit &amp; pirated products;</p>
      <p>2. To protect the legitimate interest of owners/right holders;</p>
      <p>
        3. To assign responsibilities necessary to prevent the introduction of
        counterfeit &amp; pirated products, at Pager-Ai ’s platform by
        sellers/vendors and also its supply chain;
      </p>
      <p>
        4. To encourage and create awareness of customers to purchase genuine
        products through authorized sources/ authorized distributor through Just
        Dial’s platform;
      </p>
      <p>
        5. To facilitate the mechanism to report us about the counterfeit &amp;
        pirated products on Pager-Ai ’s platform;
      </p>
      <p>
        6. To prevent the sale of counterfeit &amp; pirated products at Just
        Dial’s platform which are illegal.
      </p>
      <p>
        <b className="main_point">C. Applicability:</b>
      </p>
      <p>
        1. This policy governs each website, mobile site, application, and/or
        other service, regardless of how distributed, transmitted, published, or
        broadcast (“Service”) provided by Pager-Ai Limited and/or its affiliates
        (“we,” “us,” or “our” or “Pager-Ai ”) that links to this policy, which
        is binding on all those who access, visit and/or use Just Dial’s
        Service, whether acting as an individual or on behalf of an entity,
        including without limitation advertisers, creative &amp; media agencies,
        analytics companies, survey/research vendors/sellers, employees, users,
        customers, partners, and other service providers (collectively, “you” or
        “your”). This policy shall apply equally to all vendors, service
        providers, contractors, subcontractors, partners, agents,
        representatives, suppliers, sellers, partners, customers, users, and any
        other third parties working on behalf of Pager-Ai or accessing or using
        our platforms/service. It also applies to the head office, branches
        office and other offices of Pager-Ai and all employees/officials of
        Pager-Ai Limited. This Policy is part of Just Dial’s Terms of Service.
      </p>
      <p>
        <b className="main_point">
          D. Counterfeit &amp; Pirated Goods/Products:
        </b>
      </p>
      <p>
        1. <strong>“Counterfeit Goods”</strong> means any goods, including
        packaging, bearing without authorization a trademark which is identical
        to the trademark validly registered in respect of such goods, or which
        cannot be distinguished in its essential aspects from such a trademark,
        and which thereby infringes the rights of the owner of the trademark in
        question under the law of the country. Counterfeit goods contain a
        trademark or logo that is identical to or substantially
        indistinguishable from the trademark of another. They mimic the brand
        features of the product in an attempt to pass themselves off as a
        genuine product of the brand owner.
      </p>
      <p>
        2. <strong>“Pirated Goods”</strong> means any goods which are copies
        made without the consent of the right holder or person duly authorized
        by the right holder in the country of production and which are made
        directly or indirectly from an article where the making of that copy
        would have constituted an infringement of a copyright or a related right
        under the law of the country.
      </p>
      <p>3. Nature of Counterfeit &amp; Pirated Goods/Products:</p>
      <ol style={{ listStyleType: "none" }}>
        <li style={{ listStyleType: "none" }}>
          a) Fakes, copies, or replicas of brand name Goods/Products;
        </li>
        <li style={{ listStyleType: "none" }}>
          b) Electronics goods or accessories that use or bear the brand name,
          logo or trademark of a company that does not manufacture the
          goods/products;
        </li>
        <li style={{ listStyleType: "none" }}>
          c) Bootleg recordings from concerts or other live shows;
        </li>
        <li style={{ listStyleType: "none" }}>
          d) Unauthorized copies of movies, videos, software etc.,;
        </li>
        <li style={{ listStyleType: "none" }}>
          e) Products described as replica, imitation, clone, fake, mirror
          image, or similar terms when referring to a brand name in an attempt
          to pass off as genuine products of the brand owner;
        </li>
        <li style={{ listStyleType: "none" }}>
          f) Non-genuine products that imitate the characteristics of the brand
          in an effort to be promoted as the genuine product;
        </li>
        <li style={{ listStyleType: "none" }}>
          g) Non-genuine products bearing a brand name, label or logo;
        </li>
      </ol>
      <p>
        <b className="main_point">E. Responsibility of Sellers:</b>
      </p>
      <p>
        1. Products offered for sale on Pager-Ai ’s platform must be authentic
        &amp; genuine products. The sale of counterfeit &amp; pirated products
        is strictly prohibited. The sellers/vendors or any person on their
        behalf shall not host, display, upload, modify, publish, transmit,
        update or sell Counterfeit &amp; Pirated Goods/Products while using our
        services and also inter-alia do the following:
      </p>
      <ol style={{ listStyleType: "none" }}>
        <li style={{ listStyleType: "none" }}>
          a) Do not knowingly procure, list or sale the Counterfeit &amp;
          Pirated Goods/Products;
        </li>
        <li style={{ listStyleType: "none" }}>
          b) Promote the actual, genuine and authentic products on our platform
          in the ads/promotion/listing service;
        </li>
        <li style={{ listStyleType: "none" }}>
          c) To source &amp; sell only genuine and authentic products;
        </li>
        <li style={{ listStyleType: "none" }}>
          d) Do not bootleg, fake or pirated copy of products or content;
        </li>
        <li style={{ listStyleType: "none" }}>
          e) Do not sell the products that have been illegally replicated,
          reproduced or manufactured; and
        </li>
        <li style={{ listStyleType: "none" }}>
          f) Do not sell the products that infringe another party’s intellectual
          property rights;
        </li>
        <li style={{ listStyleType: "none" }}>
          g) Do not sell or publish the Bootleg recordings from concerts or
          other live shows on our platform;
        </li>
        <li style={{ listStyleType: "none" }}>
          h) Do not sell or publish the unauthorized copies of movies, videos,
          software etc., on our platform;
        </li>
        <li style={{ listStyleType: "none" }}>
          i) Do not sell or publish the Fakes, copies, or replicas of brand name
          Goods/Products on our platform;
        </li>
        <li style={{ listStyleType: "none" }}>
          j) Do not sell or publish the Electronics goods or accessories that
          use or bear the brand name, logo or trademark of a company that does
          not manufacture the goods/products;
        </li>
        <li style={{ listStyleType: "none" }}>
          k) Do not sell or publish the Non-genuine products that imitate the
          characteristics of the brand in an effort to be promoted as the
          genuine product, on our platform.
        </li>
      </ol>
      <p>
        <b className="main_point">F. Failure to Comply with this Policy:</b>
      </p>
      <p>
        1. We are permanently enforcing our strategy to seek customer and
        supplier co-operations for our anti-counterfeiting and piracy policy.
        Pager-Ai is not knowingly doing business with manufacturers/sellers who
        involve in manufacturing/selling of counterfeit &amp; pirated products.
      </p>
      <p>
        2. If the sellers sell or supply inauthentic or fake products and the
        same are brought to the knowledge of Pager-Ai , Pager-Ai may, at its
        sole discretion, initiate, inter-alia, the following actions against the
        vendors/sellers:
      </p>
      <ol style={{ listStyleType: "none" }}>
        <li style={{ listStyleType: "none" }}>
          a) Immediately suspend or terminate the service and de-list/remove the
          Counterfeit &amp; Pirated Goods/Products from our platform;
        </li>
        <li style={{ listStyleType: "none" }}>
          b) Destroy any inauthentic or counterfeit &amp; Pirated Goods/Products
          in our possession at your expense;
        </li>
        <li style={{ listStyleType: "none" }}>
          c) Withhold your Security Deposit, Service Fee, Sale Proceeds etc., if
          we determine that our service has been used to sell Counterfeit &amp;
          Pirated Goods/Products;
        </li>
        <li style={{ listStyleType: "none" }}>
          d) We will also initiate the legal action against the Sellers, if
          required;
        </li>
      </ol>
      <p>
        <b className="main_point">G. Pager-Ai ’s Right:</b>
      </p>
      <p>
        1. We work with manufacturers, rights holders, content owners, vendors
        and sellers to improve the ways we detect and prevent inauthentic,
        Counterfeit &amp; Pirated Goods/Products from reaching to the Just
        Dial’s users.
      </p>
      <p>
        2. We also work with rights holders and law enforcement agency to take
        and support legal action against sellers who knowingly violate this
        policy and selling the Counterfeit &amp; Pirated Goods/Products to the
        users/customers through our platform.
      </p>
      <p>
        3. We review, audit and validate the products listings periodically and
        if we find any Counterfeit &amp; Pirated Goods/Products listings, we
        remove the said suspect listings based on our review and audit of
        products.
      </p>
      <p>
        4. If we receive the complaint from users or rights owners regarding the
        Counterfeit &amp; Pirated Goods/Products, we will promptly investigate
        and take all appropriate actions to protect customers, sellers and
        rights holders.
      </p>
      <p>
        <b className="main_point">H. Report Us:</b>
      </p>
      <p>
        1. Pager-Ai does not use, nor condone the use of counterfeit products.
        We also expect our sellers to adhere to our ethical business standards
        and our practices and procedures. We strictly prohibit selling of
        counterfeiting, pirated or illegal products by any person at our
        platform. If any person is selling of counterfeiting, pirated or illegal
        products, then, as and when the same is bought to our notice, we leave
        no stone unturned to initiate a necessary action in that context.
      </p>
      <p>
        2. If you find anything on our platform, or if you believe that any
        particular products of seller or any particular seller are selling the
        Counterfeiting, Pirated or illegal Products, you can report to us, in
        accordance with our <strong>Infringement Policy</strong>, along with
        supporting documents/evidence to support your claim enabling us to
        validate your allegations.
      </p>
    </Container>
  );
};

export default Infringment;
