// import React from "react";
import React, { useState } from "react";
import {
  AppBar,
  Box,
  Container,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
const AllCategoryData = ["Phone", "Laptop", "Smartwatch", "Tablet", "More"];
const AllCategoryData2 = [
  "Repair",
  "Sell Gadgets",
  "Buy Phone",
  "Recycle",
  "Find New Phone",
  "Cashify Store",
];
const sellPhoneData = [
  {
    title: "Top Brands",
    subItem: ["Apple", "Xiaomi", "Samsung", "Oneplus", "nokia", "Poco", "More"],
  },
  {
    title: "Top Selling Phones",
    subItem: [
      "Apple iPhone 12",
      "Samsung Galaxy Note 20",
      "Apple iPhone 11",
      "One Plus 9 Pro",
      "Aiaomi Redmi Note 4",
      "Apple iPhone 6",
    ],
  },
];
const sellGadgetsData = [
  "Phone",
  "Laptop",
  "Smart Speaker",
  "Tablet",
  "Gaming Consoles",
  "iMac",
  "Smartwattch",
  "TV",
  "Earbuds",
  "DSLR Camera",
  "AC",
];
const buyPhoneData = [
  {
    title: "",
    subItem: [
      "Refurbished Phones",
      "Refurbished Laptops",
      "Redurbished Smart Watches",
    ],
  },
  {
    title: "Top Brands",
    subItem: [
      "Apple",
      "Xiaomi",
      "Samsung",
      "Oneplus",
      "Nokia",
      "Oppo",
      "Vivo",
      "All Brands",
    ],
  },
];
const findNewGadgetData1 = [
  "Find New Phone",
  "Find New Laptop",
  "Find New Smartwatch",
  "Find New Tablet",
];
const findNewGadgetData2 = [
  {
    title: "Explore",
    subItem: [
      "Videos",
      "News",
      "Reviews",
      "Articles",
      "QnA",
      "Tips and Tricks",
      "Tech News",
    ],
  },
];
const SellElectronic = () => {
  const [all, setAll] = useState(false);
  const [sellPhone, setSellPhone] = useState(false);
  const [sellGadgets, setSellGadgets] = useState(false);
  const [buyPhone, setBuyPhone] = useState(false);
  const [findNewGadget, setFindNewGadget] = useState(false);
  const matches = useMediaQuery("(max-width:600px)");
  console.log(matches);
  // const [Phone, setPhone] = useState(false);
  return (
    <div>
      <Box>
        <AppBar
          elevation={1}
          sx={{
            backgroundColor: "white",
            color: "black",
            padding: "0rem",
            //   padding: { sm: "0rem -1rem", md: "0rem 2rem" },
          }}
          position="static"
        >
          <Container>
            <Toolbar
              disableGutters={true}
              sx={{
                display: { md: "flex", xl: "flex", padding: "0rem" },
                justifyContent: "space-between",
              }}
            >
              <Box
                onMouseEnter={() => setAll(true)}
                onMouseLeave={() => setAll(false)}
                display={"flex"}
                sx={{
                  borderTop: all ? "2px solid transparent" : "",
                  "&:hover": {
                    borderTop: "2px solid #42c8b7",
                  },
                }}
                padding={2.1}
              >
                <Typography
                  fontWeight={"bold"}
                  sx={{
                    color: all ? "#42c8b7" : "",
                    fontSize: matches ? "0.6rem" : "1rem",
                  }}
                >
                  All
                </Typography>
                <KeyboardArrowDownIcon
                  fontSize="small"
                  sx={{
                    color: all ? "#42c8b7" : "",
                    transform: all ? "rotate(180deg)" : "",
                    transition: "transform 0.3s ease-in-out",
                    fontSize: matches ? "0.6rem" : "1rem",
                  }}
                />
                {all && (
                  <Box
                    sx={{
                      position: "absolute",
                      // background: "red",
                      color: "black",
                      // width: "111%",
                      width: "290px",
                      zIndex: "2",

                      // left: "1%",
                      // right: 0,
                      top: "100%",
                      // borderTop: "1px solid #f5f5f5",
                      paddingTop: "0.5rem ",
                    }}
                  >
                    <Paper sx={{ padding: "1rem" }}>
                      <Typography fontWeight={"bold"}>Sell</Typography>
                      {AllCategoryData.map((item, index) => (
                        <Box
                          key={index}
                          display={"flex"}
                          p={1}
                          my={2}
                          sx={{
                            "&:hover": {
                              color: " #42c8b7",
                              background: "#f7f7f7",
                              borderRadius: "10px",
                            },
                            cursor: "pointer",
                          }}
                        >
                          <Typography fontSize={"0.8rem"} flexGrow={1}>
                            {item}
                          </Typography>
                          <KeyboardArrowRightIcon fontSize="small" />
                        </Box>
                      ))}
                      {AllCategoryData2.map((item, i) => (
                        <Box
                          display={"flex"}
                          p={1}
                          my={2}
                          sx={{
                            "&:hover": {
                              color: " #42c8b7",
                              background: "#f7f7f7",
                              borderRadius: "10px",
                            },
                            cursor: "pointer",
                          }}
                        >
                          <Typography fontWeight={"bold"} flexGrow={1}>
                            {item}
                          </Typography>
                          <KeyboardArrowRightIcon fontSize="small" />
                        </Box>
                      ))}
                    </Paper>
                  </Box>
                )}
              </Box>
              <Box
                onMouseEnter={() => setSellPhone(true)}
                onMouseLeave={() => setSellPhone(false)}
                display={"flex"}
                sx={{
                  borderTop: sellPhone ? "2px solid transparent" : "",
                  "&:hover": {
                    borderTop: "2px solid #42c8b7",
                  },
                }}
                padding={2.1}
              >
                <Typography
                  fontWeight={"bold"}
                  sx={{
                    color: sellPhone ? "#42c8b7" : "",
                    fontSize: matches ? "0.6rem" : "1rem",
                  }}
                >
                  Sell Phone
                </Typography>
                <KeyboardArrowDownIcon
                  sx={{
                    color: sellPhone ? "#42c8b7" : "",
                    transform: sellPhone ? "rotate(180deg)" : "",
                    transition: "transform 0.3s ease-in-out",
                    fontSize: matches ? "0.6rem" : "1rem",
                  }}
                />
                {sellPhone && (
                  <Box
                    sx={{
                      position: "absolute",
                      // background: "red",
                      color: "black",
                      // width: "111%",
                      width: "290px",
                      zIndex: "2",

                      left: matches ? "5%" : "",
                      // right: 0,
                      top: "100%",
                      // borderTop: "1px solid #f5f5f5",
                      paddingTop: "0.5rem ",
                    }}
                  >
                    <Paper sx={{ padding: "1rem" }}>
                      <Typography fontWeight={"bold"}></Typography>
                      {sellPhoneData.map((item, index) => (
                        <Box key={index}>
                          <Typography fontWeight={"bold"}>
                            {item.title}
                          </Typography>
                          {item.subItem.map((item, i) => (
                            <Box
                              key={i}
                              display={"flex"}
                              p={1}
                              my={2}
                              sx={{
                                "&:hover": {
                                  color: " #42c8b7",
                                  background: "#f7f7f7",
                                  borderRadius: "10px",
                                },
                                cursor: "pointer",
                              }}
                            >
                              <Typography fontSize={"0.8rem"} flexGrow={1}>
                                {item}
                              </Typography>
                              <KeyboardArrowRightIcon fontSize="small" />
                            </Box>
                          ))}
                        </Box>
                      ))}
                    </Paper>
                  </Box>
                )}
              </Box>
              <Box
                onMouseEnter={() => setSellGadgets(true)}
                onMouseLeave={() => setSellGadgets(false)}
                display={"flex"}
                sx={{
                  borderTop: sellGadgets ? "2px solid transparent" : "",
                  "&:hover": {
                    borderTop: "2px solid #42c8b7",
                  },
                }}
                padding={2.1}
              >
                <Typography
                  fontWeight={"bold"}
                  sx={{
                    color: sellGadgets ? "#42c8b7" : "",
                    fontSize: matches ? "0.6rem" : "1rem",
                  }}
                >
                  Sell Gadgets
                </Typography>
                <KeyboardArrowDownIcon
                  sx={{
                    color: sellGadgets ? "#42c8b7" : "",
                    transform: sellGadgets ? "rotate(180deg)" : "",
                    transition: "transform 0.3s ease-in-out",
                    fontSize: matches ? "0.6rem" : "1rem",
                  }}
                />
                {sellGadgets && (
                  <Box
                    sx={{
                      position: "absolute",
                      // background: "red",
                      color: "black",
                      // width: "111%",
                      width: "290px",
                      zIndex: "2",
                      left: matches ? "5%" : "",
                      // left: "1%",
                      // right: 0,
                      top: "100%",
                      // borderTop: "1px solid #f5f5f5",
                      paddingTop: "0.5rem ",
                    }}
                  >
                    <Paper sx={{ padding: "1rem" }}>
                      {sellGadgetsData.map((item, i) => (
                        <Box
                          display={"flex"}
                          p={1}
                          my={2}
                          sx={{
                            "&:hover": {
                              color: " #42c8b7",
                              background: "#f7f7f7",
                              borderRadius: "10px",
                            },
                            cursor: "pointer",
                          }}
                        >
                          <Typography fontWeight={"bold"} flexGrow={1}>
                            {item}
                          </Typography>
                          <KeyboardArrowRightIcon fontSize="small" />
                        </Box>
                      ))}
                    </Paper>
                  </Box>
                )}
              </Box>
              <Box
                onMouseEnter={() => setBuyPhone(true)}
                onMouseLeave={() => setBuyPhone(false)}
                display={"flex"}
                sx={{
                  borderTop: buyPhone ? "2px solid transparent" : "",
                  "&:hover": {
                    borderTop: "2px solid #42c8b7",
                  },
                }}
                padding={2.1}
              >
                <Typography
                  fontWeight={"bold"}
                  sx={{
                    color: buyPhone ? "#42c8b7" : "",
                    fontSize: matches ? "0.6rem" : "1rem",
                  }}
                >
                  Buy Phone
                </Typography>
                <KeyboardArrowDownIcon
                  sx={{
                    color: buyPhone ? "#42c8b7" : "",
                    transform: buyPhone ? "rotate(180deg)" : "",
                    transition: "transform 0.3s ease-in-out",
                    fontSize: matches ? "0.6rem" : "1rem",
                  }}
                />
                {buyPhone && (
                  <Box
                    sx={{
                      position: "absolute",
                      // background: "red",
                      color: "black",
                      // width: "111%",
                      width: "290px",
                      zIndex: "2",

                      left: matches ? "5%" : "",
                      // right: 0,
                      top: "100%",
                      // borderTop: "1px solid #f5f5f5",
                      paddingTop: "0.5rem ",
                    }}
                  >
                    <Paper sx={{ padding: "1rem" }}>
                      <Typography fontWeight={"bold"}></Typography>
                      {buyPhoneData.map((item, index) => (
                        <Box key={index}>
                          <Typography fontWeight={"bold"}>
                            {item.title}
                          </Typography>
                          {item.subItem.map((item, i) => (
                            <Box
                              key={i}
                              display={"flex"}
                              p={1}
                              my={2}
                              sx={{
                                "&:hover": {
                                  color: " #42c8b7",
                                  background: "#f7f7f7",
                                  borderRadius: "10px",
                                },
                                cursor: "pointer",
                              }}
                            >
                              <Typography fontSize={"0.8rem"} flexGrow={1}>
                                {item}
                              </Typography>
                              <KeyboardArrowRightIcon fontSize="small" />
                            </Box>
                          ))}
                        </Box>
                      ))}
                    </Paper>
                  </Box>
                )}
              </Box>
              <Box
                onMouseEnter={() => setFindNewGadget(true)}
                onMouseLeave={() => setFindNewGadget(false)}
                display={"flex"}
                sx={{
                  borderTop: findNewGadget ? "2px solid transparent" : "",
                  "&:hover": {
                    borderTop: "2px solid #42c8b7",
                  },
                }}
                padding={2.1}
              >
                <Typography
                  fontWeight={"bold"}
                  sx={{
                    color: findNewGadget ? "#42c8b7" : "",
                    fontSize: matches ? "0.5rem" : "1rem",
                  }}
                >
                  Find New Gadget
                </Typography>
                <KeyboardArrowDownIcon
                  sx={{
                    color: findNewGadget ? "#42c8b7" : "",
                    transform: findNewGadget ? "rotate(180deg)" : "",
                    transition: "transform 0.3s ease-in-out",
                    fontSize: matches ? "0.5rem" : "1rem",
                  }}
                />
                {findNewGadget && (
                  <Box
                    sx={{
                      position: "absolute",
                      // background: "red",
                      color: "black",
                      // width: "111%",
                      width: "290px",
                      zIndex: "2",

                      // left: "1%",
                      right: "-2%",
                      top: "100%",

                      // borderTop: "1px solid #f5f5f5",
                      paddingTop: "0.5rem ",
                    }}
                  >
                    <Paper sx={{ padding: "1rem" }}>
                      <Typography fontWeight={"bold"}></Typography>

                      {findNewGadgetData1.map((item, i) => (
                        <Box
                          display={"flex"}
                          p={1}
                          my={2}
                          sx={{
                            "&:hover": {
                              color: " #42c8b7",
                              background: "#f7f7f7",
                              borderRadius: "10px",
                            },
                            cursor: "pointer",
                          }}
                        >
                          <Typography fontWeight={"bold"} flexGrow={1}>
                            {item}
                          </Typography>
                          <KeyboardArrowRightIcon fontSize="small" />
                        </Box>
                      ))}
                      {findNewGadgetData2.map((item, index) => (
                        <Box key={index}>
                          <Typography fontWeight={"bold"}>
                            {item.title}
                          </Typography>
                          {item.subItem.map((item, i) => (
                            <Box
                              key={i}
                              display={"flex"}
                              p={1}
                              my={2}
                              sx={{
                                "&:hover": {
                                  color: " #42c8b7",
                                  background: "#f7f7f7",
                                  borderRadius: "10px",
                                },
                                cursor: "pointer",
                              }}
                            >
                              <Typography fontSize={"0.8rem"} flexGrow={1}>
                                {item}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      ))}
                    </Paper>
                  </Box>
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    </div>
  );
};

export default SellElectronic;
