import { Box, Button, Paper, TextField, Typography } from "@mui/material";

import React from "react";
import logo from "../../assets/images/logo2.png";

import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();

  // State to store the selected country

  return (
    <div
      style={{
        width: "100vw",
        // height: "80vh",
        marginTop: "2rem",

        marginBottom: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box width={400}>
        <Paper sx={{ padding: "1rem", borderRadius: "10px" }}>
          <Box sx={{ display: "flex", justifyContent: "center", my: "2rem" }}>
            <img src={logo} alt="logo" width={200} />
          </Box>
          <Typography
            sx={{ fontWeight: "bold", mb: "1rem" }}
            variant="h6"
            textAlign={"center"}
          >
            Reset Password
          </Typography>

          <TextField
            sx={{ mb: "1rem" }}
            id="filled-basic"
            type="email"
            label="Email Address"
            fullWidth
            variant="filled"
          />

          <Box my={2}>
            <Button variant="contained" sx={{ py: "0.8rem" }} fullWidth>
              Submit
            </Button>
          </Box>

          <Box onClick={() => navigate("/sign-in")} sx={{ cursor: "pointer" }}>
            <Typography textAlign={"center"}>
              Already have account?{" "}
              <span style={{ fontWeight: "bold", color: "#00539f" }}>
                Log in{" "}
              </span>
            </Typography>
          </Box>
        </Paper>
      </Box>
    </div>
  );
};

export default ForgotPassword;
